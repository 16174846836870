import { createElement } from "react";

/**
 * Note 
 * 
 * createElement function -> props is only read only properties
 * therefor if want to do recursive way would not be possible
 */
/**
 * 
 * @param {*} tag 
 * @param {*} text 
 * @returns Html ob
 */
function generateSubElement(tag, text) {
  if(tag) {
    return createElement(tag, null, text.replaceAll('*n', '\n'));
  }
  return text.replaceAll('*n', '\n');
}

export {
  generateSubElement
}