//#region ASFA Ship Constant - asfas
export const ASFAS_INFO = "The ASFA(Ship) award provides support for ship owners, operators and lessors on the interest and qualifying related payments made in respect of arrangements obtained to finance the purchase and construction of vessels.";
export const ASFAS_BENEFIT = 'An ASFA(Ship) company will enjoy withholding tax exemption on qualifying financing arrangements entered into on or before 31 December 2031 to finance the purchase and construction of vessels.';
export const ASFAS_ELIGIBLE = `Applicant with a good track record and demonstrable business plan in respect of its shipping activities in Singapore may apply for the ASFA(Ship) award.\n\nApplicants must be able to demonstrate in its business plan how the shipping activities in Singapore will generate economic contributions in Singapore through total business spending and ensuring that the strategic and commercial decision making function will be undertaken in Singapore.`;
export const ASFAS_APPLY = 'To apply, please submit your request via *aContact Us.*a';
//#endregion

//#region ASFA Container Constant - asfac
export const ASFAC_INFO = 'The ASFA(Container) award provides support for sea container lessors on the interest and qualifying related payments made in respect of arrangements obtained to finance the purchase of containers.';
export const ASFAC_BENEFIT = 'An ASFA(Container) company will enjoy withholding tax exemption on qualifying financing arrangements entered into on or before 31 December 2031 to finance the purchase of containers.';
export const ASFAC_ELIGIBLE = `Applicant with a good track record and demonstrable business plan in respect of its container leasing activities in Singapore may apply for the ASFA(Container) award.\n\nApplicants must be able to demonstrate in its business plan how the container leasing activities in Singapore will generate economic contributions in Singapore through total business spending and ensuring that the strategic and commercial decision making function will be undertaken in Singapore.`;
export const ASFAC_APPLY = 'To apply, please submit your request via *aContact Us.*a';
//#endregion