import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormHtmlForm,
  FormHtmlHead,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
} from "../../../Common/ApplicationForm";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import { fetch } from '../../../Common/API';

WHTQEntityTypeForm.defaultProps = {
  formname: "Withholding Tax (WHT) Exemption",
  formstatus: "New",
  formId: "whtqentitytype",
  appFormBaseURL: `${config.appFormBaseURL}`,
  schema: "WHT_Q_EntityType",
};

export default function WHTQEntityTypeForm({
  formname,
  formid,
  formstatus,
  formId,
  appFormBaseURL,
  schema,
  props,
}) {
  const [isBusy, setBusy] = useState(true)
  const [appFormJson, setAppFormJson] = useState({})
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let path = params.get("path");
  let createdData = {};

  if (!_id) {
    //new applcation
    _id = "";
  } else {
    // update existing application
    createdData._id = _id.slice(0);
    _id = "submission/" + _id;
  }

  let appFormURL = `${appFormBaseURL}/${formId}/${_id}`;
  let createURL = `${appFormBaseURL}/${formId}/submission`;
  let currentForm = {};
  const [thisForm, setThisForm] = useState();

  const [formReadOnly, setFormReadOnly] = useState(false);

  const [fetchingform, setFetchingform] = useState(true);

  let alreadySubmitted = false;

  useEffect(() => {
    async function getform() {
      setBusy(true);
      let json = await fetch(appFormURL, 'GET', null);
      if (json) {
        setBusy(false);
        setAppFormJson(json.data);
      }
    }
    getform();
  }, [])

  const formReady = (form) => {
    setFetchingform(false);
    currentForm = form;
    currentForm.nosubmit = true;
    setThisForm(currentForm);

    if (createdData._id) {
      // load existing form and hide eligibility page
      currentForm.component.hidden = true;
      currentForm.setPage(1);
    }

    currentForm.on("proceed", async () => {
      let q_data = currentForm.submission.data.container.questionType1;
      if (q_data !== "MSI-ML-Container" && q_data !== "ASFA-Container") { //Check if it's not container???
        if (path === "eligibility") { //check by query
          history.push({
            pathname: "/SDshipEligibilityForm",
            search: `?path=eligibility&selected=${q_data}`,
          });
        } else {
          history.push({
            pathname: "/WHTShipForm",
            search: `?path=application&selected=${q_data}`,
          });
        }
      } else {
        if (path === "eligibility") {
          history.push({
            pathname: "/SDContainerEligibility",
            search: `?path=eligibility&selected=${q_data}`,
          });
        } else {
          history.push({
            pathname: "/WHTContainerForm",
            search: `?path=application&selected=${q_data}`,
          });
        }
      }
    });
  };

  const onRender = async () => {
    onRenderGeneric(
      currentForm,
      alreadySubmitted,
      createdData,
      createURL,
      appFormBaseURL,
      formId
    );
  };

  const onChange = async (event) => {
    onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
  };

  const onSubmit = async (submission) => {
    onSubmitGeneric(
      submission,
      currentForm,
      alreadySubmitted,
      createdData,
      createURL,
      appFormBaseURL,
      formId,
      schema
    );
  };

  const onError = async (errors) => {
    onErrorGeneric(errors);
  };

  const onSubmitDone = (submission) => {
    onSubmitDoneGeneric(submission);
  };

  const onCustomEvent = async ({ type, component, data, event }) => {
    // This function is intentionally empty. 
  }

  return (
    <div className="applicationform">
      <Container>
        <FormHtmlHead formname={formname} schema={schema}/>
        <div style={{ display: "block" }}>
          <Typography variant="h2">Entity Type</Typography>
        </div>
        {/* <FormHtmlTitle formname={formname} formid={formid} formstatus={formstatus}/> */}
        <br />
        <FormHtmlForm
          fetchingform={fetchingform}
          formReadOnly={formReadOnly}
          props={props}
          appFormURL={appFormURL}
          onChange={onChange}
          onError={onError}
          formReady={formReady}
          onSubmit={onSubmit}
          onSubmitDone={onSubmitDone}
          onRender={onRender}
          onCustomEvent={onCustomEvent}
          isBusy={isBusy}
          appFormJson={appFormJson}
         />
      </Container>
    </div>
  );
}
