import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { IconArrowLeft } from "@tabler/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import grpdiscusspic from "../../Images/Img-ExploreSchemes.png";
import SchemeBanner from "./SchemeBanner";
import Group1 from "../HomePage/Components/SchemesCardGroups/Group1";
import IDPCard from "../HomePage/Components/SchemesCardGroups/IDPCard";
import MSICard from "../HomePage/Components/SchemesCardGroups/MSICard";
import WHTCard from "../HomePage/Components/SchemesCardGroups/WHTCard";
import "./ExploreSchemes.css";
import ASFACard from "../HomePage/Components/SchemesCardGroups/ASFACard";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  borderBottom: "2px solid #eaecf0",
  width: "100%",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 55,
    height: "5px",
    width: "100%",
    backgroundColor: "#0088CE",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    height: "18px",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: "bold",
    marginRight: 15,
    fontFamily: "var(--font-family-univers-55roman)",
    fontSize: 16,

    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d0343a",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExploreSchemes(props) {
  const location = useLocation();
  const [tab, setTab] = useState(0);
  useEffect(() => {
    if (location.state?.tab === "Grants") setTab(1);
    if (location.state?.tab === "Incentives") setTab(2);
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const history = useHistory();
  const myStyle = {
    width: '100%',
    display: 'flex',
    marginTop: {xs: 4},
    justifyContent: {xs: 'center', sm: 'start', md: 'start'}
  };

  return (
    <div className="exploreschemes">
  <div className="scheme-background">       
    <div className="backbanner-contactus" />
    <Container>
      <br />
      <Button
        onClick={() => history.push("/Home")}
        className="nav-back-btn"
      >
        <IconArrowLeft color="#666" />
      </Button>
      <Breadcrumbs
        sx={{ display: "inline-block", verticalAlign: "middle" }}
        aria-label="breadcrumb"
      >
        <Link
          underline="hover"
          onClick={() => history.push("/Home")}
          mx={1}
          className="active-link univers-65-bold-scarpa-flow-12px"
        >
          Home
        </Link>
        <Link
          underline="none"
          mx={1}
          className="disabled-link univers-65-bold-scarpa-flow-12px"
        >
          Explore Schemes
        </Link>
      </Breadcrumbs>
      <br />
      <br />
      <Grid container>
      <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6.5 : 12}>
      <br />
            <Typography variant="h2">Explore Schemes</Typography>
            <Typography className="univers-55roman-normal-mine-shaft-22px">
              Select the type of grants and incentives for more information.
            </Typography>
        
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img
            style={{
              display: useMediaQuery("(min-width: 1090px)") ? "initial" : "none",
              // Ensure the image takes full width of its container
            }}
            alt="Explore-schemes"
            src={grpdiscusspic}
            id="exploreschemespic"
            className="contactUsImg"
          />
        </Grid>
      </Grid>
    </Container>
    <Container>
      <Box mt={useMediaQuery("(min-width: 768px)") ? 1 : 2}>
        <StyledTabs
          value={tab}
          onChange={handleChange}
          sx={{ borderBottom: 0, marginBottom: 2 }}
        >
          <StyledTab label="All Schemes" {...a11yProps(0)} />
          <StyledTab label="Grants" {...a11yProps(1)} />
          <StyledTab label="Incentives" {...a11yProps(2)} />
        </StyledTabs>

        <TabPanel value={tab} index={0}>
          <Group1 />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Box>
            <Grid container sx={myStyle} columnSpacing={{xs: 0, sm: 2, md: 2}}>
              <Grid item>
                <WHTCard />
              </Grid>
              <Grid item>
                <MSICard />
              </Grid>
              <Grid item>
                <ASFACard />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box>
            <Grid container sx={myStyle}>
              <Grid item>
                <IDPCard />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
      <br />
      <br />
    </Container>
  </div>

  <div className="bannerplace">
    <div className="overlap-banner">
      <SchemeBanner />
    </div>
  </div>
</div>

  );
}
