import { useState } from "react";
import { Box, Container, Grid, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { styled, } from "@mui/material/styles";
import Group1 from "./SchemesCardGroups/Group1";
import IDPCard from "./SchemesCardGroups/IDPCard";
import MSICard from "./SchemesCardGroups/MSICard";
import WHTCard from "./SchemesCardGroups/WHTCard";
import ASFACard from "./SchemesCardGroups/ASFACard";

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{
    children: <span className="MuiTabs-indicatorSpan" />
  }} />))({
    marginBottom: "22px",
    "& .MuiTabs-indicator": {
      height: "5px",
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 55,
      width: '100%',
      backgroundColor: '#0065BD'
    },
  });
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: "bold",
    marginRight: 15,
    fontSize: 16,
    lineHeight: 4.5,
    textTransform: "capitalize",
    "&:hover": {
    },
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
    },
  })
);

//End of JS Styling

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Scheme() {
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const myStyle = {
    width: '100%',
    display: 'flex',
    marginTop: {xs: 4},
    justifyContent: {xs: 'center', sm: 'start', md: 'start'}
  };

  return (
    <div id="scheme-background">
      <Container className="schemecontainer">
        <p className="indicator-1 small-blue-text " style={{paddingTop: 20}}>OUR SCHEMES</p>
        <br />
        <Stack direction={useMediaQuery('(min-width: 768px)') ? "row" : "column"} >
          <div className="big-div">
            <span className="span0">MPA provides a range of schemes </span>
            <span>to develop and grow maritime </span>
            <span>businesses in Singapore.</span>
          </div>
          <Box className="subtitle">
            One Maritime Portal brings MPA grants and incentives for maritime
            businesses into one place, so that it is easier to find and apply for the
            grants and incentives you need.
          </Box>
        </Stack>
        <Box className="subtabs">
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab label="All Schemes" {...a11yProps(0)} />
            <StyledTab label="Grants" {...a11yProps(1)} />
            <StyledTab label="Incentives" {...a11yProps(2)} />
          </StyledTabs>
          <TabPanel value={tab} index={0}>
            <Group1 />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box sx={myStyle} columnSpacing={{xs: 0, sm: 2, md: 2}}>
              <Stack direction="row" columnGap={2}>
                <IDPCard />
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Grid container sx={myStyle} columnSpacing={{xs: 0, sm: 2, md: 2}}>
              <Grid item>
                <WHTCard />
              </Grid>
              <Grid item>
                <MSICard />
              </Grid>
              <Grid item>
                <ASFACard />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </div>
  );
}
