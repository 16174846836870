import { Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function MLaward() {
  const history = useHistory();
  return (
    <>
      <Typography variant="h3">
        MSI - Maritime Leasing <nobr>(MSI-ML)</nobr> Award
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-22px">
        The MSI-ML award encourages entities to use Singapore as their capital
        and funding base to finance their vessels or sea containers.
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        Benefits
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
        <p>
          Ship or container leasing companies, business trusts or partnerships
          will enjoy tax concessions for up to 5 years on their qualifying
          leasing income under the MSI-ML award. For ship leasing entities, 
          an alternative basis of tax based on the net tonnage of the ships 
          on qualifying shipping income is also available. 
        </p>

        <p>
          An approved manager of the asset-owning entity will be awarded a
          concessionary tax rate of 10% on its qualifying management income.
        </p>

        <p>
          Operating and qualifying finance leases are both covered under the
          MSI-ML award to allow asset-owning flexibility in leasing and
          chartering options.
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        Who is eligible 
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
        <p>
          Applicant with a good track record and demonstrable business plan in
          respect of its ship and container financing operations in Singapore
          may apply for the MSI-ML award on or before 31 December 2031.
        </p>

        <p>
          Applicant must be able to demonstrate in its business plan how the
          ship or container financing operations will generate economic
          contributions in Singapore through total business spending, number of
          professionals employed, undertaking additional activities from
          Singapore, and ensuring that the strategic or commercial decision
          making functions will be undertaken in Singapore.
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        How to apply?
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        To apply, please submit your request via&nbsp;
        <a
          className="clickContactText"
          onClick={() => history.push("/ContactUs")}
        >
          Contact Us
        </a>
        .
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        For more information, please refer to the Frequently Asked Questions (
        <a className="clickContactText" onClick={() => history.push("/FAQs")}>
          FAQs
        </a>
        ) page.
      </Typography>
    </>
  );
}
