import { Typography } from "@mui/material";
import React, { createElement } from "react";
import "./schemeTextFormat.css";


function generateHeaderWithInfo(header, text) {
  return (
    <>
    <Typography variant="h3">{header}</Typography>
    <br />
    <Typography className="univers-55roman-normal-mine-shaft-22px">{text}</Typography>
    </>
  )
}

/**
 * function will help to replace text of specific format with html tag
 * 
 * function cannot handle multiple tag -- to be improve
 * 
 * *b and **b -> for bold text
 * 
 * *s and **s -> for strong text
 * 
 * @param {*} header 
 * @param {*} text 
 * @returns a formatted html text 
 */
function generateSubHeaderWithInfo (header, text) {
  //define as a html element of p
  let htmlElement = createElement('p', null, text);
  //replace for <b> tag
  if(htmlElement.props.children.indexOf('*b') > -1 && htmlElement.props.children.match(/(?<=\*b)(.*?)(?=\*b)/)){
    const strArr = htmlElement.props.children.split('*b');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('b', null, strArr[1]),
      strArr[2],
    )
  }
  
  //replace for <strong> tag
  if(htmlElement.props.children.indexOf('*s') > -1  && htmlElement.props.children.match(/(?<=\*s)(.*?)(?=\*s)/)) {
    const strArr = htmlElement.props.children.split('*s');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('s', null, strArr[1]),
      strArr[2],
    )
  }
  

  return (
    <>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>{header}</Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2 customTypographyText"><p>{htmlElement}</p></Typography>
    </>
  )
}

/**
 * function will help to replace text of specific format with html tag
 * 
 * function cannot handle multiple tag -- to be improve
 * 
 * *b and **b -> for bold text
 * 
 * *s and **s -> for strong text
 * 
 * *a and **a -> for a tag and redirect to email
 * @param {*} header 
 * @param {*} text 
 * @param {*} email 
 * @returns return formatted html text with redirect to email
 */
function generateSubHeaderWithInfoAndEmail(header, text, email) {
  //replace for <b> tag
  let htmlElement = text;
  if(text.match(/(?<=\*b)(.*?)(?=\*b)/)){
    const strArr = text.split('*b');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('b', null, strArr[1]),
      strArr[2],
    )
  }

  //replace for <strong> tag
  if(htmlElement.match(/(?<=\*s)(.*?)(?=\*s)/)) {
    const strArr = text.split('*s');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('s', null, strArr[1]),
      strArr[2],

    )
  }

  //replace for <a> tag with email
  if(htmlElement.match(/(?<=\*a)(.*?)(?=\*a)/)) {
    const strArr = text.split('*a');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('a', { href: `mailto:${email}` } , strArr[1]),
      strArr[2],

    )
  }

  return (
    <>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>{header}</Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2 customTypographyText"><React.Fragment>{htmlElement}</React.Fragment></Typography>
    </>
  )
}

/**
 * 
 * @param {string} header 
 * @param {string} text 
 * @param {function} onClickHandler 
 * @returns 
 */
function generateSubHeaderToRedirect(header, text, onClickHandler) {
  //replace for <b> tag
  let htmlElement = text;
  if(text.match(/(?<=\*b)(.*?)(?=\*b)/)){
    const strArr = text.split('*b');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('b', null, strArr[1]),
      strArr[2],
    )
  }

  //replace for <strong> tag
  if(htmlElement.match(/(?<=\*s)(.*?)(?=\*s)/)) {
    const strArr = text.split('*s');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('s', null, strArr[1]),
      strArr[2],

    )
  }

  //replace for <a> tag with email
  if(htmlElement.match(/(?<=\*a)(.*?)(?=\*a)/)) {
    const strArr = text.split('*a');
    htmlElement = createElement(
      'p',
      null,
      strArr[0],
      createElement('a', { className: 'clickContactText', onClick: () => {onClickHandler()} }, strArr[1]),
      strArr[2],

    )
  }

  return (
    <>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>{header}</Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2 customTypographyText"><React.Fragment>{htmlElement}</React.Fragment></Typography>
    </>
  )
}

export {
  generateHeaderWithInfo,
  generateSubHeaderWithInfo,
  generateSubHeaderWithInfoAndEmail,
  generateSubHeaderToRedirect,
}