import { Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function AISaward() {
  const history = useHistory();
  return (
    <>
      <Typography variant="h3">
        MSI - Approved International Shipping Enterprise <nobr>(MSI-AIS)</nobr>
        &nbsp;Award
      </Typography>
      <br />
      <Typography
        className="univers-55roman-normal-mine-shaft-22px"
        align="justify"
      >
        The MSI-AIS award seeks to encourage international ship owners and ship
        operators to establish their commercial shipping operations in
        Singapore.
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        Benefits
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
      An MSI-AIS company will enjoy tax exemption or an alternative basis of tax 
      based on the net tonnage of the ships on qualifying shipping income for either:
      </Typography>
      <ul>
        <li>
          <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
            a 10-year renewable period; or
          </Typography>
        </li>
        <li>
          <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
            a 5-year non-renewable period, with the option of graduating to the
            10-year renewable award at the end of the 5-year period, if
            qualifying conditions are met.
          </Typography>
        </li>
      </ul>

      <br />
      <Typography variant="h4" fontSize={22}>
        Eligibility
      </Typography>
      <br />
      <Typography
        className="univers-55roman-normal-mine-shaft-16px typo-text2"
        align="justify"
      >
        Applicant with a good track record and demonstrable business plan in
        respect of its shipping operations in Singapore may apply for the
        MSI-AIS award.
      </Typography>
      <br />
      <Typography
        className="univers-55roman-normal-mine-shaft-16px typo-text2"
        align="justify"
      >
        Applicant must be able to demonstrate in its business plan how the
        shipping operations will generate economic contributions in Singapore
        through total business spending, undertaking additional activities from
        Singapore, and ensuring that the strategic or commercial decision making
        functions will be undertaken in Singapore.
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        How to apply?
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        To apply, please submit your request via&nbsp;
        <a
          className="clickContactText"
          onClick={() => history.push("/ContactUs")}
        >
          Contact Us
        </a>
        .
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        For more information, please refer to the Frequently Asked Questions (
        <a className="clickContactText" onClick={() => history.push("/FAQs")}>
          FAQs
        </a>
        ) page.
      </Typography>
    </>
  );
}
