import moment from "moment";
import { SwapsCheckbox } from "./SwapsCheckboxValidation";
import { getIDPEDHInfo } from "./EDH";
import Utils from "formiojs/utils";

const DateTransferCertContainer = (currentForm) => {
  // Period Of Transfer Cert
  const dateOfTransferCertComp = currentForm.getComponent("dateOfTransferCert");
  let dateTransferCert = moment(
    currentForm.submission.data.containerNonTax.containerLender
      .dateOfTransferCert ?? 0
  );
  let dateOfTransferCertMinDate = moment(
    dateOfTransferCertComp.component.datePicker.minDate ?? 0
  );

  // Handle reset date of transfer cert
  if (dateOfTransferCertMinDate !== 0 && dateTransferCert !== 0) {
    if (dateTransferCert < dateOfTransferCertMinDate) {
      currentForm.submission.data.containerNonTax.containerLender.dateOfTransferCert =
        null;
      setTimeout(function () {
        dateOfTransferCertComp.triggerChange();
        dateOfTransferCertComp.triggerRedraw();
      }, 10);
    }
  }
  return [dateOfTransferCertComp];
};

const populateEDH = async (currentForm, EDH) => {
  let [user] = EDH;

  let borrowerIndexZero =
    currentForm.submission.data.containerParticulars.containerBorrower
      .dataGridBorrower[0].containerCompanyRegisteredAddress;
  borrowerIndexZero.borrowerName = user.companyDetails.entityName;
  borrowerIndexZero.uniqueEntityNumber = user.uen;
  borrowerIndexZero.country =
    user.formattedAddress?.residentCountry || "Singapore";
  borrowerIndexZero.postalCode = user.formattedAddress.postalCode;
  borrowerIndexZero.block = user.formattedAddress.blockHouseNumber;
  borrowerIndexZero.street = user.formattedAddress.streetName;
  borrowerIndexZero.floor = user.formattedAddress.levelNumber;
  borrowerIndexZero.unit = user.formattedAddress.unitNumber.padStart(2, "0");
  borrowerIndexZero.buildingName = user.formattedAddress.buildingName;

  currentForm.submission.data.configContainer["companyInvolved"] =
    user.companyDetails.entityName;

  const keysToEnable = [
    "borrowerName",
    "uniqueEntityNumber",
    "country",
    "postalCode",
    "block",
    "street",
    "floor",
    "unit",
    "buildingName",
  ];
  let borrowerDetails = Utils.searchComponents(currentForm.components, {
    type: "textfield",
  });
  borrowerDetails.forEach((borrower) => {
    if (keysToEnable.includes(borrower.key)) {
      borrower.disabled = false;
    }
  });
};

const populateQ1 = (currentForm, selected) => {
  let r;
  switch (selected) {
    case "SRS":
      r = "r1";
      break;
    case "MSI-AIS":
      r = "r2";
      break;
    case "MSI-ML-Ship":
      r = "r3";
      break;
    case "MSI-ML-Container":
      r = "r4";
      break;
    case "ASFA-Ship":
      r = "r5";
      break;
    case "ASFA-Container":
      r = "r6";
      break;
    default:
      return null;
  }
  currentForm.submission.data.containerParticulars.containerBorrower.entityType =
    r;

  currentForm.redraw(); //render the updated option ??
};

const CommonUpdateValidation_TotalNonQualifyingAmountFinanced = (
  currentForm
) => {
  const nonQualifyingAmountFinancedComp = currentForm.getComponent(
    "nonQualifyingAmountFinanced"
  );
  if (nonQualifyingAmountFinancedComp) {
    nonQualifyingAmountFinancedComp.pristine = false;
    nonQualifyingAmountFinancedComp.triggerChange();
  }
};

const CommonUpdateValidation_NonQualifyingAmountFinancedCost = (
  currentForm
) => {
  const dataGridNonQualifyComp = currentForm.getComponent("dataGridNonQualify");
  if (dataGridNonQualifyComp) {
    let length = dataGridNonQualifyComp.components.length;
    for (let i = 0; i < length; ++i) {
      if (
        dataGridNonQualifyComp.components[i].component.key ===
        "nonQualifyingAmountFinancedCost"
      ) {
        dataGridNonQualifyComp.components[i].pristine = false;
        dataGridNonQualifyComp.components[i].triggerChange();
      }
    }
  }
};

const CommonUpdateValidation_TypeOfFinancingArrangementObtained = (
  currentForm
) => {
  const selectBoxes = [
    currentForm.getComponent("selectBoxesQFA_1"),
    currentForm.getComponent("selectBoxesQFA_2"),
    currentForm.getComponent("selectBoxesQFA_3"),
  ];

  selectBoxes.forEach((selectBox) => {
    if (selectBox) {
      selectBox.pristine = false;
      selectBox.triggerChange();
    }
  });
};

const CommonUpdateValidation_QualifyingPayment = (currentForm, key) => {
  SwapsCheckbox(currentForm);

  if (key === "isThereAnySwapArrangementInTheSameFinancingAgreement") {
    let totalQualifyingRelatedPayments1 = currentForm.getComponent(
      "totalQualifyingRelatedPayments1"
    );
    if (totalQualifyingRelatedPayments1) {
      totalQualifyingRelatedPayments1.triggerChange();
    }
  }
};

const CommonPopulateQuestionEDH = async (currentForm, selected, UEN) => {
  try {
    populateQ1(currentForm, selected);
    let EDH = await getIDPEDHInfo(UEN);
    populateEDH(currentForm, EDH);
  } catch (e) {
    alert("Unable to retrieve company details");
  }
};

const CommonHandleAllDropdownListMetadataFix_WHTContainer = (currentForm) => {
  CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountry(currentForm);
  CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountryAgreement(
    currentForm
  );
  CommonHandleAllDropdownListMetadataFix_FinanceEquityInjectionContainer(
    currentForm
  );
  CommonHandleAllDropdownListMetadataFix_PurposeOfFinancingArrangementContainer(
    currentForm
  );
  CommonHandleAllDropdownListMetadataFix_MSI_StatusAwarded(currentForm);
};

const CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountry = (
  currentForm
) => {
  let countryLength =
    currentForm?.submission?.data?.containerNonTax?.containerLender
      ?.dataGridLender?.length;
  if (countryLength && countryLength > 0) {
    for (let index = 0; index < countryLength; ++index) {
      if (
        currentForm?.submission?.metadata?.selectData?.containerNonTax
          ?.containerLender?.dataGridLender[index]?.countries !== undefined
      ) {
        currentForm.submission.metadata.selectData.containerNonTax.containerLender.dataGridLender[
          index
        ].countries.data.country =
          currentForm?.submission?.data?.containerNonTax?.containerLender?.dataGridLender[
            index
          ]?.countries;
      }
    }
  }
};
const CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountryAgreement = (
  currentForm
) => {
  let countryLength =
    currentForm?.submission?.data?.containerNonTax?.containerNonTaxResident
      ?.dataGridNonTaxResident?.length;
  if (countryLength && countryLength > 0) {
    for (let index = 0; index < countryLength; ++index) {
      if (
        currentForm?.submission?.metadata?.selectData?.containerNonTax
          ?.containerNonTaxResident?.dataGridNonTaxResident !== undefined
      ) {
        if (
          currentForm?.submission?.metadata?.selectData?.containerNonTax
            ?.containerNonTaxResident?.dataGridNonTaxResident[index]
            ?.containerNonTax !== undefined
        ) {
          currentForm.submission.metadata.selectData.containerNonTax.containerNonTaxResident.dataGridNonTaxResident[
            index
          ].containerNonTax[index].countries.data.country =
            currentForm?.submission?.data?.containerNonTax?.containerNonTaxResident?.dataGridNonTaxResident[
              index
            ]?.containerNonTax?.countries;
        }
      }
    }
  }
};
const CommonHandleAllDropdownListMetadataFix_FinanceEquityInjectionContainer = (
  currentForm
) => {
  const submission =
    currentForm?.submission?.metadata?.selectData?.containerQualifyingDetails
      ?.containerPurposeOfFa?.containerFaTable?.containerFa3?.containerSpv
      ?.dataGridSPV ?? {};
  if (submission) {
    let countryLength =
      currentForm?.submission?.data?.containerQualifyingDetails
        ?.containerPurposeOfFa?.containerFaTable?.containerFa3?.containerSpv
        ?.dataGridSPV?.length;
    if (countryLength && countryLength > 0) {
      for (let index = 0; index < countryLength; ++index) {
        if (
          currentForm?.submission?.metadata?.selectData
            ?.containerQualifyingDetails?.containerPurposeOfFa?.containerFaTable
            ?.containerFa3?.containerSpv?.dataGridSPV !== undefined
        ) {
          currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerFaTable.containerFa3.containerSpv.dataGridSPV[
            index
          ].countries.data.country =
            currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerFaTable?.containerFa3?.containerSpv?.dataGridSPV[
              index
            ]?.countries;
        }
      }
    }
  }
};

const CommonHandleAllDropdownListMetadataFix_MSI_StatusAwarded = (
  currentForm
) => {
  const borrowerDataGrid =
    currentForm?.submission?.metadata?.selectData?.containerParticulars
      ?.containerBorrower?.dataGridBorrower ?? [];
  const submissionDataGrid =
    currentForm?.submission?.data?.containerParticulars?.containerBorrower
      ?.dataGridBorrower ?? [];

  const msiStatusMapping = {
    m1: "MSI-ACIE",
    m2: "MSI-ACIE(Local ASPV)",
    m3: "MSI-ACIE(Sister)",
  };
  const businessTypeMapping = {
    b1: "Business Trust",
    b2: "Company",
    b3: "General Partnership",
    b4: "Limited Liability Partnership",
    b5: "Limited Partnership",
  };

  if (borrowerDataGrid && borrowerDataGrid.length > 0) {
    submissionDataGrid.forEach((submission, index) => {
      if (
        borrowerDataGrid[index]?.containerCompanyRegisteredAddress !== undefined
      ) {
        const { containerCompanyRegisteredAddress } = borrowerDataGrid[index];
        const { msiStatusAwardedAppliedFor, businessType } =
          submission.containerCompanyRegisteredAddress;

        if (msiStatusAwardedAppliedFor) {
          containerCompanyRegisteredAddress[index].msiStatusAwardedAppliedFor =
            {
              label: msiStatusMapping[msiStatusAwardedAppliedFor] || "",
            };
        }

        if (businessType) {
          containerCompanyRegisteredAddress[index].businessType = {
            label: businessTypeMapping[businessType] || "",
          };
        }
      }
    });
  }
};

const CommonHandleAllDropdownListMetadataFix_PurposeOfFinancingArrangementContainer =
  (currentForm) => {
    const submission =
      currentForm?.submission?.metadata?.selectData?.containerQualifyingDetails
        ?.containerPurposeOfFa?.containerFaTable?.containerFa4?.containerSpv
        ?.dataGridSPV ?? {};
    if (submission) {
      let countryLength =
        currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerPurposeOfFa?.containerFaTable?.containerFa4?.containerSpv
          ?.dataGridSPV?.length;
      if (countryLength && countryLength > 0) {
        for (let index = 0; index < countryLength; ++index) {
          if (
            currentForm?.submission?.metadata?.selectData
              ?.containerQualifyingDetails?.containerPurposeOfFa
              ?.containerFaTable?.containerFa4?.containerSpv?.dataGridSPV[index]
              ?.countries !== undefined
          )
            currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerFaTable.containerFa4.containerSpv.dataGridSPV[
              index
            ].countries.data.country =
              currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerFaTable?.containerFa4?.containerSpv?.dataGridSPV[
                index
              ]?.countries;
        }
      }
    }
  };

const CommonHandleWHTContainerDate = (currentForm, changed) => {
  if (changed?.changed?.component?.key === "periodOfFinancingTo") {
    let datePeriod = moment(
      currentForm.submission.data.containerNonTax.containerLender
        .periodOfFinancingTo ?? 0
    );
    const periodOfFinancingToComp = currentForm.getComponent(
      "periodOfFinancingTo"
    );
    let datePeriodMinDatePeriodFinanceTo = moment(
      periodOfFinancingToComp.component.datePicker.minDate ?? 0
    );
    if (
      datePeriodMinDatePeriodFinanceTo != 0 &&
      datePeriod != 0 &&
      datePeriod < datePeriodMinDatePeriodFinanceTo
    ) {
      currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingTo =
        null;

      if (periodOfFinancingToComp) {
        periodOfFinancingToComp.triggerChange();
        periodOfFinancingToComp.triggerRedraw();
      }
    }
    const periodOfCoveredToComp = currentForm.getComponent(
      "datePeriodCoveredTo"
    );
    let datePeriodCoveredTo =
      moment(
        currentForm?.submission?.data?.containerNonTax?.containerNonTaxResident
          ?.datePeriodCoveredTo
      ) ?? 0;
    let datePeriodMinDate = moment(
      periodOfCoveredToComp.component.datePicker.minDate ?? 0
    );
    if (
      datePeriodMinDate != 0 &&
      datePeriodCoveredTo != 0 &&
      datePeriodMinDate < datePeriodCoveredTo
    ) {
      if (datePeriod < datePeriodCoveredTo) {
        currentForm.submission.data.containerNonTax.containerNonTaxResident.datePeriodCoveredTo =
          null;

        if (periodOfCoveredToComp) {
          periodOfCoveredToComp.triggerChange();
          periodOfCoveredToComp.triggerRedraw();
        }
      }
    }
    // Handle endDate
    const endDateComp = currentForm.getComponent("endDate");
    let dateEndDate =
      moment(
        currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerQualifyingPeriod?.endDate
      ) ?? 0;
    let dateEndDateMinDate = moment(
      endDateComp.component.datePicker.minDate ?? 0
    );
    if (
      dateEndDateMinDate != 0 &&
      dateEndDate != 0 &&
      dateEndDateMinDate < dateEndDate
    ) {
      if (datePeriod < dateEndDate)
        currentForm.submission.data.containerQualifyingDetails.containerQualifyingPeriod.endDate =
          null;
    }
    if (endDateComp) {
      endDateComp.triggerChange();
      endDateComp.triggerRedraw();
    }
  }
};

export {
  DateTransferCertContainer,
  CommonUpdateValidation_TotalNonQualifyingAmountFinanced,
  CommonUpdateValidation_NonQualifyingAmountFinancedCost,
  CommonUpdateValidation_TypeOfFinancingArrangementObtained,
  CommonUpdateValidation_QualifyingPayment,
  CommonPopulateQuestionEDH,
  CommonHandleAllDropdownListMetadataFix_MSI_StatusAwarded,
  CommonHandleAllDropdownListMetadataFix_WHTContainer,
  CommonHandleWHTContainerDate,
};
