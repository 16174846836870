import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import moment from "moment"
import './banner.css';
import { generateSubElement } from '../../Common/GenericTextUtility';

export default function Banner({message, showCloseButton=true, showWarningIcon=true, textAlignCenter=false, startDate = "", endDate =""}) {
  const [hideBanner, setHideBanner] = useState(false);
  let isValid = false;
  const currentDate = moment();

  if(!message) {
    return <></>;
  }

  if(Array.isArray(message) && message.length <= 0){
    return <></>
  }

  if(startDate) {
    const startDateTime = moment(startDate);
    isValid = (currentDate > startDateTime)
  }

  if(startDate && endDate) {
    const startDateTime = moment(startDate);
    const endDateTime = moment(endDate);
    isValid = currentDate.isBetween(startDateTime, endDateTime, '[]');
  }

  return (
    isValid ? 
    !hideBanner && 
    (
      <div className='bannerCustom'>
        <Stack 
          direction="row" 
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div className='bannerCustomMessage' style={{ textAlign: textAlignCenter ? "center" : "" }}  >
            {showWarningIcon && <WarningAmberIcon/>}
            {message.map((item) => {
              return generateSubElement(item?.tag, item.text)
            })}
          </div>
          {showCloseButton && <div>
            <IconButton onClick={() => setHideBanner(true)}>
              <CloseIcon />
            </IconButton>
          </div>}
        </Stack>
      </div>
    )
    : 
    <></>
  );
};