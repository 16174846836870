import { Card, Typography, useMediaQuery } from "@mui/material";
import { IconArrowRight } from '@tabler/icons';
import { useHistory } from 'react-router-dom'
import "../GuidedJourney.css"

export default function ASFACard() {
  
  const history = useHistory();

  return (
    <>
      {useMediaQuery('(max-width: 465px)') ? 
        <Card className="resp465 journey-card" sx={{width: 300, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <Typography variant='h4'>Approved Shipping Financing Arrangement (ASFA)</Typography>
            Enjoy withholding tax exemption on interest and related payments made in respect of qualifying financing arrangements. 
            <br />
            <a className='clickContactText' onClick={() => history.push('/ASFAScheme')}>Learn more <IconArrowRight /></a>
          </div>
        </Card>
        : 
        <Card className="journey-card" sx={{ height: 400, width: 400, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <Typography variant='h4'>Approved Shipping Financing Arrangement (ASFA)</Typography>
            <br />
            Enjoy withholding tax exemption on interest and related payments made in respect of qualifying financing arrangements.  
            <br />
            <a className='clickContactText' onClick={() => history.push('/ASFAScheme')}>Learn more <IconArrowRight /></a>
          </div>
        </Card>
      }
    </>
  );
}