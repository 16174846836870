import { dispatchStartAutoLogOutTimerEvent } from "./DispatchEvent";
import jwt from "jwt-decode";
import AESUtil from "../Common/AESUtil";
import Formio from "formiojs/Formio";
class loginHelper {
	setLoginInfo = (loginInfo) => {
		this.deleteLoginInfo();
		this.setRefreshJWT(loginInfo.RefreshToken);
		this.setLoginUser(loginInfo.PublicUser);
		this.setLoginJwt(loginInfo.AccessToken);
		sessionStorage.removeItem("error");
	};

  /**
   * NRIC is empty in the localstorage
   * @returns loginUser Object
   */
	getLoginUser = () => {
		const loginUser = localStorage.getItem("loginUser");
    if (loginUser) {
			const loginResult = JSON.parse(loginUser);
			loginResult.Email = AESUtil.decrypt(loginResult.Email);
			loginResult.Name = AESUtil.decrypt(loginResult.Name);
			return loginResult;
		}
		return null;
	};

	setLoginUser = (loginUser) => {
		localStorage.setItem("loginUser", JSON.stringify(loginUser));
	};

	getLoginJwt = () => {
		return localStorage.getItem("loginJwt");
	};

	getErrorMessage = () => {
		return localStorage.getItem("errorMessage");
	};

	setErrorMessage = (errorMessage) => {
		localStorage.setItem("errorMessage", errorMessage);
	};

	removeErrorMessage = () => {
		localStorage.removeItem("errorMessage");
	};

	getFormioToken = () => {
		return localStorage.getItem("formioToken");
	};

	setFormioToken = (formioToken) => {
		Formio.setToken(formioToken)
			.then(() => {
				localStorage.setItem("formioToken", formioToken);
			})
			.catch((e) => {
				console.warn(e);
				Formio.setToken(this.getLoginJwt());
				localStorage.setItem("formioToken", this.getLoginJwt());
			});
	};

	setDate = (date) => {
		localStorage.setItem("date", date != null ? date.getTime() : null);
	};

	getDate = () => {
		const date = localStorage.getItem("date");
		return date ? new Date(parseInt(date)) : null;
	};

	decodeJwt = () => {
		return jwt(localStorage.getItem("loginJwt"));
	};

	setLoginJwt = (jwt) => {
		localStorage.setItem("loginJwt", jwt);
		this.setFormioToken(jwt);
		localStorage.removeItem("date");
		dispatchStartAutoLogOutTimerEvent();
	};

	getRefreshJWT = () => {
		return localStorage.getItem("refreshJWT");
	};

	setRefreshJWT = (refreshJwt) => {
		localStorage.setItem("refreshJWT", refreshJwt);
	};

	setLogoutFlag = (logoutFlag) => {
		localStorage.setItem("logoutFlag", logoutFlag);
	};

	getLogoutFlag = () => {
		return localStorage.getItem("logoutFlag");
	};

	deleteLoginInfo = () => {
		localStorage.removeItem("loginJwt");
		localStorage.removeItem("formioToken");
		localStorage.removeItem("refreshJWT");
		localStorage.removeItem("loginUser");
		localStorage.removeItem("date");
		localStorage.removeItem("frontEndKey");
		localStorage.removeItem("frontEndIv");
		Formio.setToken() // Empty param = If the value of this parameter is empty, then the token will be deleted from localStorage.
			.then(() => {})
			.catch(() => {
				Formio.setToken();
			});
		this.setLogoutFlag(true);
	};

	deleteLogoutFlag = () => {
		localStorage.removeItem("logoutFlag");
	};
}

const loginHelperSingleton = new loginHelper();
export default loginHelperSingleton;
