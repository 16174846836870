import { Breadcrumbs, Button, Container, Grid, Link, Paper, Typography, useMediaQuery, Box, styled, Tab, Tabs } from '@mui/material';
import { useHistory } from "react-router-dom";
import { IconArrowLeft } from '@tabler/icons';
import bannerpic from '../../../Images/image_afsa.png';
import SelectMenu from '../../FAQs/menuOptions';
import React from 'react';
import PropTypes from 'prop-types';
import ASFAShip from './ASFAShip';
import ASFAContainer from './ASFAContainer';

//#region Constant
const menuASFAShipText = 'Approved Shipping Financing Arrangement (Ship) Award – ASFA(Ship)';
const menuASFAContainerText = 'Approved Shipping Financing Arrangement (Container) Award – ASFA(Container)';

const asfaShipStyleTabLabel = <p>{menuASFAShipText}</p>;
const asfaContainerStyleTabLabel = <p>{menuASFAContainerText}</p>;

const StyledTabs = styled(Tabs)({
  marginLeft: 30,
  marginRight: 30,
  width: 740,
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent"
  }
});
const StyledTab = styled(Tab)({
  fontSize: 18,
  fontFamily: "Univers-65Bold",
  lineHeight: '28px',
  textTransform: 'none',
  textAlign: 'left',
  margin: '10px 0px',
  padding: '20px 0px',
  WebkitAlignItems: 'baseline',
  borderBottom: '2px solid #eaecf0',
  '&.Mui-selected': {
    borderBottom: '2px solid #0065bd'
  }
});
const StyledTabPanel = styled(TabPanel)({
  width: 2100
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

//#endregion


export default function ASFAScheme() {
  //#region State and useHook
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const checkMin600 = useMediaQuery('(min-width: 600px)')
  //#endregion

  //#region Function
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickMenu = (event) => {
    setValue(event.target.value)
  };
  //#endregion



  const menuitem = [{ key: 0, text: menuASFAShipText }, { key: 1, text: menuASFAContainerText }]

  return (
    <div className="exploreschemes">
      <div className="scheme-background">
        <div className="backbanner" />
        <Container>
          <br />
          <Button onClick={() => history.push({
            pathname: "/ExploreSchemes",
            tab: 'Incentives'
          })}
            className='nav-back-btn'>
            <IconArrowLeft color='#666' />
          </Button>
          <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
            <Link underline='hover'
              onClick={() => history.push('/Home')}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Home
            </Link>
            <Link underline='hover'
              onClick={() => history.push({
                pathname: "/ExploreSchemes",
                tab: 'Incentives'
              })}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Incentives
            </Link>
            <Link underline='none'
              mx={1} className='disabled-link univers-65-bold-scarpa-flow-12px'>
              Approved Shipping Financing Arrangement
            </Link>
          </Breadcrumbs>
          <br />
          <br />
          <Grid container>
            <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6 : 12}>
              <Typography variant='h2'>Approved Shipping Financing Arrangement</Typography>
              <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-l) !important', mt: 1 }}>
              The Approved Shipping Financing Arrangement (ASFA) award provides withholding tax exemption on qualifying related payments made in respect of the purchase and construction of ships, and purchase of containers under qualifying financing arrangements entered into on or before 31 December 2031. 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <img 
                style={{ display: useMediaQuery('(min-width: 1090px)') ? 'initial' : 'none' }}
                src={bannerpic} 
                id='idpschemespic' 
                alt='ASFA'
              />
             </Grid>
          </Grid>
        </Container>
        <Container className='schemeasfa'>
          <div className='mobilefaq ' style={{ display: useMediaQuery('(max-width: 900px)') ? "" : "none" }}>
            <br />
            <br />
            <Typography variant='h4'>Categories</Typography>
            <SelectMenu handleClickMenu={handleClickMenu} active={value} menuitem={menuitem} />
          </div>
          <Paper elevation={0} sx={{ position: 'relative', mt: checkMin600 ? 12.3 : 5, p: 3, border: '1px solid #EAECF0', borderRadius: 3 }}>
            <Box sx={{ flexGrow: 1, display: checkMin600 ? "flex" : "none" }}>
              <StyledTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <StyledTab label={asfaShipStyleTabLabel} {...a11yProps(0)} />
                <StyledTab label={asfaContainerStyleTabLabel} {...a11yProps(1)} />
              </StyledTabs>
              {checkMin600 ?
                <>
                  <StyledTabPanel value={value} index={0}>
                    <ASFAShip />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={1}>
                    <ASFAContainer />
                  </StyledTabPanel>
                </>
                : <></>
              }
            </Box>
            {useMediaQuery('(max-width: 599px)') ?
              <Box sx={{ flexGrow: 1, display: 'flex', }}>
                <StyledTabPanel value={value} index={0}>
                  <ASFAShip />
                </StyledTabPanel>
                <StyledTabPanel value={value} index={1}>
                  <ASFAContainer />
                </StyledTabPanel>
              </Box>
              : <></>
            }
          </Paper>
        </Container>
      </div>
    </div>
  )
}