import {ASFAC_INFO, ASFAC_BENEFIT, ASFAC_ELIGIBLE, ASFAC_APPLY} from './ASFAConstant';
import {generateHeaderWithInfo, generateSubHeaderWithInfo, generateSubHeaderToRedirect} from '../Common/genericFunction';
import { useHistory } from "react-router-dom";


export default function ASFAContainer() {
  const history = useHistory();
    
    function redirectToContactUs() {
      history.push('/ContactUs');
      return;
    }

  return (
    <>
      {generateHeaderWithInfo('Approved Shipping Financing Arrangement (Container) Award – ASFA(Container)', ASFAC_INFO)}
      {generateSubHeaderWithInfo('Benefits', ASFAC_BENEFIT)}
      {generateSubHeaderWithInfo('Who is eligible', ASFAC_ELIGIBLE)}
      {generateSubHeaderToRedirect('How to apply', ASFAC_APPLY, redirectToContactUs)}
    </>
  )
}