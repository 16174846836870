import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function ASFACard() {
  const history = useHistory();
  return (
    <>
      <Card className="scheme-card clickText">
        <div className="overlay-group" onClick={() => history.push("/ASFAScheme")}>
          <div className="image-afsa"></div>
          <div className="image-text-wht" onClick={() => history.push("/ASFAScheme")}>Approved Shipping Financing Arrangement (ASFA)</div>
        </div>
        <p className="scheme-description" onClick={() => history.push("/ASFAScheme")}>
          The ASFA supports the ownership and management of ships and sea containers from Singapore. 
        </p>
      </Card>
    </>
  );
}