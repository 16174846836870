import moment from "moment";
import {
  ComponentFieldType,
  ComponentUpdateType,
  ImoType,
} from "./CommonEnumTypes";
import Utils from "formiojs/utils";
import { SwapsCheckbox } from "./SwapsCheckboxValidation";
import { getIDPEDHInfo } from "./EDH";
import { getDownload } from "./API";
import DOMPurify from 'dompurify';
import { ConsoleLog } from "./Logger";
import { HelperFormatBaseUrl } from "./GenericForm";
import {config} from "../config";

const DateTransferCertShip = (currentForm) => {
  // Period Of Transfer Cert
  const dateOfTransferCertComp = currentForm.getComponent("dateOfTransferCert");
  let dateTransferCert = moment(
    currentForm.submission.data.containerNonTax.containerLender
      .dateOfTransferCert ?? 0
  );
  let dateOfTransferCertMinDate = moment(
    dateOfTransferCertComp.component.datePicker.minDate ?? 0
  );

  // Handle reset date of transfer cert
  if (dateOfTransferCertMinDate !== 0 && dateTransferCert !== 0) {
    if (dateTransferCert < dateOfTransferCertMinDate) {
      currentForm.submission.data.containerNonTax.containerLender.dateOfTransferCert =
        null;
      setTimeout(function () {
        dateOfTransferCertComp.triggerChange();
        dateOfTransferCertComp.triggerRedraw();
      }, 10);
    }
  }
  return [dateOfTransferCertComp];
};

const TriggerEvent_TotalNonQualifyingAmountFinanced = (currentForm) => {
  const dateOfTransferCertComp = currentForm.getComponent(
    "nonQualifyingAmountFinanced"
  );
  if (dateOfTransferCertComp) {
    dateOfTransferCertComp.triggerChange();
    dateOfTransferCertComp.triggerRedraw();
  }
};

const Helper_GetDataGridItem_ByFieldType = (
  index,
  datagrid,
  componentFieldType
) => {
  let result = null;
  switch (componentFieldType) {
    case ComponentFieldType.IMO_NUMBER: {
      result =
        datagrid.components[index].components[0].columns[1][1].columns[1][0];
      break;
    }
    case ComponentFieldType.FIELD_OF_REGISTRY: {
      result = datagrid.components[index].components[0].columns[0][0];
      break;
    }
    case ComponentFieldType.STATUS_VESSEL_DETAIL: {
      result = datagrid.components[index].components[1].columns[1][3];
      break;
    }
    case ComponentFieldType.CHECKBOX_NO_IMO: {
      result =
        datagrid.components[index].components[0].columns[1][1].columns[0][0];
      break;
    }
    case ComponentFieldType.CHECKBOX_NO_IMO_FOR_EDIT: {
      result =
        datagrid.components[index].components[8].columns[1][1].columns[0][0];
      break;
    }
    case ComponentFieldType.CHECKBOX_NO_IMO_FOR_REPLACEMENT: {
      result =
        datagrid.components[index].components[11].columns[1][1].columns[0][0];
      break;
    }
    case ComponentFieldType.CONTAINER_FOR_EDIT_CHECKBOX: {
      result = datagrid.components[index].components[5].components[0];
      break;
    }
    case ComponentFieldType.NORMAL_COLUMN_TOP: {
      result = datagrid.components[index].components[0];
      break;
    }
    case ComponentFieldType.NORMAL_COLUMN_BOTTOM: {
      result = datagrid.components[index].components[1];
      break;
    }
    case ComponentFieldType.EDIT_COLUMN_TOP: {
      result = datagrid.components[index].components[8];
      break;
    }
    case ComponentFieldType.EDIT_COLUMN_BOTTOM: {
      result = datagrid.components[index].components[9];
      break;
    }
    case ComponentFieldType.REPLACEMENT_COLUMN_TOP: {
      result = datagrid.components[index].components[11];
      break;
    }
    case ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM: {
      result = datagrid.components[index].components[12];
      break;
    }
    case ComponentFieldType.DELETE_VESSEL_CHECKBOX: {
      result = datagrid.components[index].components[5].components[2];
      break;
    }
    default:
      break;
  }

  return result;
};

/**
 * Function trigger certain events of the selected component
 */
const Helper_TriggerComponent_ByComponentUpdateType = (
  component,
  componentUpdateType
) => {
  switch (componentUpdateType) {
    case ComponentUpdateType.TRIGGER_CHANGE_ONLY: {
      component.triggerChange();
      break;
    }
    case ComponentUpdateType.TRIGGER_REDRAW_ONLY: {
      component.triggerRedraw();
      break;
    }
    case ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW: {
      component.triggerChange();
      component.triggerRedraw();
      break;
    }
    default:
      break;
  }
};

const populateEDH = async (currentForm, EDH) => {
  let [user] = EDH;

  let borrowerIndexZero =
    currentForm.submission.data.containerParticulars.containerBorrower
      .dataGridBorrower[0].containerCompanyRegisteredAddress;
  borrowerIndexZero.borrowerName = user.companyDetails.entityName;
  borrowerIndexZero.uniqueEntityNumber = user.uen;
  borrowerIndexZero.country =
    user.formattedAddress?.residentCountry || "Singapore";
  borrowerIndexZero.postalCode = user.formattedAddress.postalCode;
  borrowerIndexZero.block = user.formattedAddress.blockHouseNumber;
  borrowerIndexZero.street = user.formattedAddress.streetName;
  borrowerIndexZero.floor = user.formattedAddress.levelNumber;
  borrowerIndexZero.unit = user.formattedAddress.unitNumber.padStart(2, "0");
  borrowerIndexZero.buildingName = user.formattedAddress.buildingName;

  currentForm.submission.data.configContainer["companyInvolved"] =
    user.companyDetails.entityName;

  const keysToEnable = [
    "borrowerName",
    "uniqueEntityNumber",
    "country",
    "postalCode",
    "block",
    "street",
    "floor",
    "unit",
    "buildingName",
  ];
  let borrowerDetails = Utils.searchComponents(currentForm.components, {
    type: "textfield",
  });
  borrowerDetails.forEach((borrower) => {
    if (keysToEnable.includes(borrower.key)) {
      borrower.disabled = false;
    }
  });
};

const populateQ1 = (currentForm, selected) => {
  let r;
  switch (selected) {
    case "SRS":
      r = "r1";
      break;
    case "MSI-AIS":
      r = "r2";
      break;
    case "MSI-ML-Ship":
      r = "r3";
      break;
    case "MSI-ML-Container":
      r = "r4";
      break;
    case "ASFA-Ship":
      r = "r5";
      break;
    case "ASFA-Container":
      r = "r6";
      break;
    default:
      return null;
  }
  currentForm.submission.data.containerParticulars.containerBorrower.entityType =
    r;

  currentForm.redraw(); //render the updated option ??
};

/**
 * Function check if string contains whitespaces
 * Return :
 *    true: contain whitespace
 *   false: no contain whitespace
 */
function Helper_String_Contains_Whitespace(str) {
  return /\s/.test(str);
}

const Helper_ComponentUpdate = (currentForm, propertyName, visible) => {
  if(!currentForm)
    return;
  const comp = currentForm.getComponent(propertyName);
  if(!comp)
    return;
  comp.parentVisible = visible;
  comp.triggerChange();
  comp.triggerRedraw();
}

/**
 * Function search for the component in the vessel particular's datagrid and trigger certain events of that component
 */
const CommonVesselDatagrid_GetComponent_Update = (
  e,
  currentForm,
  componentFieldType = ComponentFieldType.NORMAL_COLUMN_BOTTOM,
  componentUpdateType = componentUpdateType.NONE
) => {
  if (componentUpdateType === ComponentUpdateType.NONE) return;

  let listOfDatagrid = Utils.searchComponents(currentForm.components, {
    type: "datagrid",
  });

  listOfDatagrid.forEach((eachDataGrid) => {
    if (eachDataGrid.key === "dataGridVessel1") {
      let vesselDGSize =
        currentForm.submission.data.containerParticulars.containerShipVessel
          .dataGridVessel1.length;

      for (let i = 0; i < vesselDGSize; ++i) {
        let datagridItem = Helper_GetDataGridItem_ByFieldType(
          i,
          eachDataGrid,
          componentFieldType
        );

        if (datagridItem !== null && datagridItem._data === e) {
          Helper_TriggerComponent_ByComponentUpdateType(
            datagridItem,
            componentUpdateType
          );
          break;
        }
      }
    }
  });
};

/**
 * Function returns index of the datagrid that is triggering the input changed event.
 * Return:
 *    -1 : Not found
 *  >= 0 : Found index
 */
const CommonVesselDatagrid_GetComponent_Index = (
  e,
  currentForm,
  componentFieldType = ComponentFieldType.IMO_NUMBER
) => {
  let foundIndex = -1;

  // Store the target datagrid in a variable for efficiency
  const targetDataGrid =
    currentForm.submission.data.containerParticulars.containerShipVessel
      .dataGridVessel1;

  // Search for the target datagrid
  for (const eachDataGrid of Utils.searchComponents(currentForm.components, {
    type: "datagrid",
  })) {
    if (eachDataGrid.key === "dataGridVessel1") {
      // Use the length of the target datagrid for efficiency
      const vesselDGSize = targetDataGrid.length;

      // Search for the matching data grid item
      for (let i = 0; i < vesselDGSize; ++i) {
        let datagridItem = Helper_GetDataGridItem_ByFieldType(
          i,
          eachDataGrid,
          componentFieldType
        );

        // Exit early if the matching data grid item is found
        if (datagridItem && datagridItem._data === e) {
          foundIndex = i;
          return foundIndex;
        }
      }
    }
  }

  return foundIndex;
};

/**
 * Function clears the datagrid element of selected index
 */
const CommonVesselDatagrid_GetComponent_ClearFields = (
  targetIndex,
  currentForm,
  resetAll
) => {
  const dataGridVessel =
    currentForm.submission.data.containerParticulars.containerShipVessel
      .dataGridVessel1;

  if (resetAll === true) {
    dataGridVessel[targetIndex].containerDataGrid.vesselCost = "";
    dataGridVessel[targetIndex].containerDataGrid.deliveryDate3 = "";
  }

  dataGridVessel[targetIndex].containerDataGrid.dateOfRegistration = "";
  dataGridVessel[targetIndex].containerDataGrid.typeOfVesselDescription = "";
  dataGridVessel[targetIndex].containerDataGrid.yearBuilt = "";
  dataGridVessel[targetIndex].containerDataGrid.vesselName = "";
  dataGridVessel[targetIndex].containerDataGrid.grossTonnage = "";
  dataGridVessel[targetIndex].containerDataGrid.netTonnage = "";
};

/**
 * Function clears the datagrid element of selected index
 * Return
 * true : IMO number is valid
 * false: Imo number is invalid
 */
const CommonVesselDatagrid_IsImoNumber_Valid = (imoNumber) => {
  return (
    imoNumber !== undefined &&
    imoNumber !== "" &&
    (imoNumber.toString().length === 7 || imoNumber.toString().length === 8) &&
    imoNumber !== "0000000" &&
    imoNumber !== "00000000" &&
    Helper_String_Contains_Whitespace(imoNumber) === false
  );
};

/**
 * Function returns duplicate index if duplicates exist, else return -1
 * Return:
 *    -1 : no duplicate index found
 *  >= 0 : found duplicates index at this
 */
const CommonVesselDatagrid_GetDuplicatedComponent_Index = (
  e,
  currentForm,
  imoType = ImoType.NORMAL
) => {
  const dgLength =
    currentForm.submission.data.containerParticulars.containerShipVessel
      .dataGridVessel1.length;
  const duplicateIndex = -1;
  let newImoNumber = e.imoNumber;
  if (imoType === ImoType.NORMAL) newImoNumber = e.imoNumber;
  else if (imoType === ImoType.EDIT) newImoNumber = e.edit_imoNumber;
  else if (imoType === ImoType.REPLACE) newImoNumber = e.replacement_imoNumber;
  else if (imoType === ImoType.DELETE) newImoNumber = e.temp_imo;

  if (
    newImoNumber === undefined ||
    newImoNumber === null ||
    newImoNumber === ""
  ) {
    return -1;
  }

  for (let i = 0; i < dgLength; ++i) {
    const dataGridContainer =
      currentForm.submission.data.containerParticulars.containerShipVessel
        .dataGridVessel1[i].containerDataGrid;
    const { containerCheckbox } = dataGridContainer;

    if (dataGridContainer === e) {
      continue; // skip ownself
    }

    if (
      containerCheckbox.editVessel &&
      dataGridContainer.edit_imoNumber === newImoNumber
    ) {
      return i; // return index as duplicate index
    } else if (
      containerCheckbox.replaceVesselButton &&
      dataGridContainer.replacement_imoNumber === newImoNumber
    ) {
      return i; // return index as duplicate index
    } else if (
      containerCheckbox.deleteVessel &&
      dataGridContainer.temp_imo === newImoNumber
    ) {
      return i; // return index as duplicate index
    } else if (dataGridContainer.imoNumber === newImoNumber) {
      return i; // return index as duplicate index, for checking of newly added entry
    }
  }

  return duplicateIndex;
};

const CommonUpdateValidation_TotalNonQualifyingAmountFinanced = (
  currentForm
) => {
  const nonQualifyingAmountFinancedComp = currentForm.getComponent(
    "nonQualifyingAmountFinanced"
  );
  if (nonQualifyingAmountFinancedComp) {
    nonQualifyingAmountFinancedComp.pristine = false;
    nonQualifyingAmountFinancedComp.triggerChange();
  }
};

const CommonUpdateValidation_NonQualifyingAmountFinancedCost = (
  currentForm
) => {
  const dataGridNonQualifyComp = currentForm.getComponent("dataGridNonQualify");
  if (dataGridNonQualifyComp) {
    let length = dataGridNonQualifyComp.components.length;
    for (let i = 0; i < length; ++i) {
      if (
        dataGridNonQualifyComp.components[i].component.key ===
        "nonQualifyingAmountFinancedCost"
      ) {
        dataGridNonQualifyComp.components[i].pristine = false;
        dataGridNonQualifyComp.components[i].triggerChange();
      }
    }
  }
};

const CommonUpdateValidation_TypeOfFinancingArrangementObtained = (
  currentForm
) => {
  const selectBoxes = [
    currentForm.getComponent("selectBoxesQFA_1"),
    currentForm.getComponent("selectBoxesQFA_2"),
    currentForm.getComponent("selectBoxesQFA_3"),
  ];

  selectBoxes.forEach((selectBox) => {
    if (selectBox) {
      selectBox.pristine = false;
      selectBox.triggerChange();
    }
  });
};

const CommonUpdateValidation_QualifyingPayment = (currentForm, key) => {
  SwapsCheckbox(currentForm);

  if (key === "isThereAnySwapArrangementInTheSameFinancingAgreement") {
    let totalQualifyingRelatedPayments1 = currentForm.getComponent(
      "totalQualifyingRelatedPayments1"
    );
    if (totalQualifyingRelatedPayments1) {
      totalQualifyingRelatedPayments1.triggerChange();
    }
  }
};

const CommonPopulateQuestionEDH = async (currentForm, selected, UEN) => {
  try {
    populateQ1(currentForm, selected);
    let EDH = await getIDPEDHInfo(UEN);
    populateEDH(currentForm, EDH);
  } catch (e) {
    alert("Unable to retrieve company details");
  }
};

const CommonHandleFlagOfRegistryMetadataFix = (currentForm, index) => {
  // Dropdownlist: Countries
  if (
    currentForm?.submission?.metadata?.selectData?.containerParticulars
      ?.containerShipVessel?.dataGridVessel1 !== undefined
  ) {
    if (
      currentForm.submission.metadata.selectData.containerParticulars
        .containerShipVessel.dataGridVessel1?.[index]?.containerDataGrid?.[index]
        .countries !== undefined
    ) {
      currentForm.submission.metadata.selectData.containerParticulars.containerShipVessel.dataGridVessel1[
        index
      ].containerDataGrid[index].countries.data.country =
        currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
          index
        ].containerDataGrid.countries;
    }
  }
};

const CommonHandleAllDropdownListMetadataFix = (currentForm) => {
  // Dropdownlist: Countries


  if (
    currentForm?.submission?.metadata?.selectData?.containerParticulars
      ?.containerShipVessel?.dataGridVessel1 !== undefined
  ) {
    let countryLength =
      currentForm?.submission?.data?.containerParticulars?.containerShipVessel
        ?.dataGridVessel1?.length;
    if (countryLength !== undefined && countryLength > 0) {
      for (let index = 0; index < countryLength; ++index) {
        if(currentForm.submission.metadata.selectData?.containerParticulars
          ?.containerShipVessel?.dataGridVessel1?.length-1 < index || currentForm.submission.metadata.selectData?.containerParticulars
          ?.containerShipVessel?.dataGridVessel1?.[index]?.containerDataGrid?.length-1 < index) {
            break;
          }
        if (
          currentForm.submission.metadata.selectData?.containerParticulars
            ?.containerShipVessel?.dataGridVessel1?.[index]?.containerDataGrid[index]?.countries?.data?.country !==
          undefined
        ) {
          currentForm.submission.metadata.selectData.containerParticulars.containerShipVessel.dataGridVessel1[
            index
          ].containerDataGrid[index].countries.data.country =
            currentForm?.submission?.data?.containerParticulars?.containerShipVessel?.dataGridVessel1?.[
              index
            ]?.containerDataGrid?.countries;
        }
      }
    }
  }
};

const CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountry = (
  currentForm
) => {

  

  let lenderLength =
    currentForm?.submission?.data?.containerNonTax?.containerLender
      ?.dataGridLender?.length;
  if (lenderLength !== undefined && lenderLength > 0) {
    for (let index = 0; index < lenderLength; ++index) {
      if(currentForm.submission.metadata?.selectData?.containerNonTax?.containerLender?.dataGridLender?.[index]?.countries !== undefined) {
        currentForm.submission.metadata.selectData.containerNonTax.containerLender.dataGridLender[
          index
        ].countries.data.country =
          currentForm?.submission?.data?.containerNonTax?.containerLender?.dataGridLender[
            index
          ]?.countries;
      }
    }
  }
};

const CommonUpdateVesselDetailsInNewForm = (
  newData,
  oldData,
  newDataMeta,
  oldDataMeta,
  checkboxType,
  i
) => {
  // if (
  //   newDataMeta === null ||
  //   newDataMeta === undefined ||
  //   newDataMeta?.containerDataGrid === undefined ||
  //   newDataMeta?.containerDataGrid === null
  // )
  //   return;
  if(!newData)
    return;

  let selectedCountryMeta = null;
  let selectedPropetyType = "";
  switch (checkboxType) {
    case ImoType.EDIT: {
      selectedCountryMeta =
        oldDataMeta?.containerDataGrid[i].edit_countries?.data.country;
      selectedPropetyType = "edit_";
      break;
    }
    case ImoType.REPLACE: {
      selectedCountryMeta =
        oldDataMeta?.containerDataGrid[i].replacement_countries?.data.country;
      selectedPropetyType = "replacement_";
      break;
    }
    default: {
      selectedCountryMeta =
        oldDataMeta?.containerDataGrid[i].countries?.data.country;
      selectedPropetyType = "";
      break;
    }
  }

  selectedCountryMeta =
    selectedCountryMeta ||
    oldDataMeta?.containerDataGrid[i].countries?.data.country;
  if (newDataMeta?.containerDataGrid) {
    newDataMeta.containerDataGrid[i].countries.data.country =
      selectedCountryMeta;
  }
  newData.containerDataGrid.containerCheckbox.editVessel = false;
  newData.containerDataGrid.containerCheckbox.replaceVesselButton = false;

  let checkboxName = selectedPropetyType;
  const propertyMapping = {
    cbIMO: checkboxName + "cbIMO",
    countries: checkboxName + "countries",
    imoNumber: checkboxName + "imoNumber",
    vesselName: checkboxName + "vesselName",
    vesselCost: checkboxName + "vesselCost",
    grossTonnage: checkboxName + "grossTonnage",
    netTonnage: checkboxName + "netTonnage",
    deliveryDate3: checkboxName + "deliveryDate",
    dateOfRegistration: checkboxName + "dateOfRegistration",
    typeOfVessel: checkboxName + "typeOfVessel",
    yearBuilt: checkboxName + "yearBuilt",
  };
  if (checkboxType === ImoType.NONE) {
    propertyMapping.deliveryDate3 = checkboxName + "deliveryDate3";
  }
  Object.entries(propertyMapping).forEach(([newProperty, oldProperty]) => {
    newData.containerDataGrid[newProperty] =
      oldData.containerDataGrid?.[oldProperty];
  });
};

const CommonHandleAllDropdownListMetadataFix_WHTShip = (currentForm) => {
  CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountry(currentForm);
  CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountryAgreement(
    currentForm
  );
};
const CommonHandleAllDropdownListMetadataFix_WHTShip_entityType = (
  currentForm,
  entityType
) => {
  CommonHandleAllDropdownListMetadataFix_FinanceEquityInjection(
    currentForm,
    entityType
  );
  CommonHandleAllDropdownListMetadataFix_PurposeOfFinancingArrangementSRS6(
    currentForm,
    entityType
  );
};
const CommonHandleAllDropdownListMetadataFixV1 = (currentForm, entityType) => {
  CommonHandleAllDropdownListMetadataFix(currentForm);
  CommonHandleAllDropdownListMetadataFix_WHTShip(currentForm)
  CommonHandleAllDropdownListMetadataFix_WHTShip_entityType(currentForm, entityType);
};

const CommonHandleAllDropdownListMetadataFix_NonTaxResidentCountryAgreement = (
  currentForm
) => {
  if(!currentForm?.submission?.metadata?.selectData?.containerNonTax?.containerNonTaxResident)
      return;
  
  let countryLength =
    currentForm?.submission?.data.containerNonTax?.containerNonTaxResident
      ?.dataGridNonTaxResident?.length;
  if (countryLength !== undefined && countryLength > 0) {
    for (let index = 0; index < countryLength; ++index) {
    
      if (currentForm?.submission?.metadata?.selectData?.containerNonTax?.containerNonTaxResident?.dataGridNonTaxResident?.[index]?.containerNonTax?.[index]?.countries !== undefined)

        currentForm.submission.metadata.selectData.containerNonTax.containerNonTaxResident.dataGridNonTaxResident[
          index
        ].containerNonTax[index].countries.data.country =
          currentForm?.submission?.data?.containerNonTax?.containerNonTaxResident?.dataGridNonTaxResident[
            index
          ]?.containerNonTax?.countries;
      }
    
    
  }
};

const CommonHandleAllDropdownListMetadataFix_FinanceEquityInjection = (
  currentForm,
  entityType
) => {
  populateQ1(currentForm, entityType);

  if(!currentForm?.submission?.metadata?.selectData?.containerQualifyingDetails?.containerPurposeOfFa)
      return;

  if (entityType === "r1") {
    let countryLength =
      currentForm?.submission?.data?.containerQualifyingDetails
        ?.containerPurposeOfFa?.containerSRS?.containerTableSRS5?.dataGridSPV
        ?.length;
    if (countryLength && countryLength > 0) {
      for (let index = 0; index < countryLength; ++index) {
        if(currentForm?.submission?.metadata?.selectData?.containerQualifyingDetails?.containerPurposeOfFa?.containerSRS?.containerTableSRS5?.dataGridSPV?.[index
        ]?.countries !== undefined) {
          currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerSRS.containerTableSRS5.dataGridSPV[
            index
          ].countries.data.country =
            currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerSRS?.containerTableSRS5?.dataGridSPV[
              index
            ]?.countries;
        }
      }
    }
  } else if (entityType === "r2" || entityType === "r3" || entityType === "r5") {
    let countryLength =
      currentForm?.submission?.data?.containerQualifyingDetails
        ?.containerPurposeOfFa?.containerMSI?.containerTableMSI5?.containerSpv
        ?.dataGridSPV?.length;
    if (countryLength && countryLength > 0) {
      for (let index = 0; index < countryLength; ++index) {
        if(currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerPurposeOfFa?.containerMSI?.containerTableMSI5?.containerSpv?.dataGridSPV?.[
            index
          ]?.countries
           !== undefined) {
          currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerMSI.containerTableMSI5.containerSpv.dataGridSPV[
            index
          ].countries.data.country =
            currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerMSI?.containerTableMSI5?.containerSpv?.dataGridSPV[
              index
            ]?.countries;
        }
      }
    }
  }
};

const CommonHandleAllDropdownListMetadataFix_PurposeOfFinancingArrangementSRS6 =
  (currentForm, entityType) => {
    populateQ1(currentForm, entityType);
    if (entityType === "r1") {
      let countryLength =
        currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerPurposeOfFa?.containerSRS?.containerTableSRS6
          ?.dataGridSPV?.length;
      if (countryLength && countryLength > 0) {
        for (let index = 0; index < countryLength; ++index) {
          if(currentForm.submission.metadata?.selectData?.containerQualifyingDetails?.containerPurposeOfFa?.containerSRS?.containerTableSRS6
            ?.dataGridSPV?.[index]?.countries !== undefined) {
            currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerSRS.containerTableSRS6.dataGridSPV[
              index
            ].countries.data.country =
              currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerSRS?.containerTableSRS6?.dataGridSPV[
                index
              ]?.countries;
          }
        }
      }
    } else if (entityType === "r2" || entityType === "r3" || entityType === "r5") {
      let countryLength =
        currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerPurposeOfFa?.containerMSI?.containerTableMSI6?.containerSpv
          ?.dataGridSPV?.length;
      if (countryLength !== undefined && countryLength > 0) {
        for (let index = 0; index < countryLength; ++index) {
          if(currentForm.submission.metadata?.selectData?.containerQualifyingDetails?.containerPurposeOfFa?.containerMSI?.containerTableMSI6?.containerSpv
            ?.dataGridSPV?.[index]?.countries !== undefined) {
            currentForm.submission.metadata.selectData.containerQualifyingDetails.containerPurposeOfFa.containerMSI.containerTableMSI6.containerSpv.dataGridSPV[
              index
            ].countries.data.country =
              currentForm?.submission?.data?.containerQualifyingDetails?.containerPurposeOfFa?.containerMSI?.containerTableMSI6?.containerSpv?.dataGridSPV[
                index
              ]?.countries;
          }
        }
      }
    }
  };

const CommonHandleWHTShipDate = (currentForm, changed) => {
  if (changed?.changed?.component?.key === "periodOfFinancingTo") {
    let datePeriod = moment(
      currentForm.submission.data.containerNonTax.containerLender
        .periodOfFinancingTo ?? 0
    );
    const periodOfFinancingToComp = currentForm.getComponent(
      "periodOfFinancingTo"
    );
    let datePeriodMinDatePeriodFinanceTo = moment(
      periodOfFinancingToComp.component.datePicker.minDate ?? 0
    );
    if (
      datePeriodMinDatePeriodFinanceTo != 0 &&
      datePeriod != 0 &&
      datePeriod < datePeriodMinDatePeriodFinanceTo
    ) {
      currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingTo =
        null;

      if (periodOfFinancingToComp) {
        periodOfFinancingToComp.triggerChange();
        periodOfFinancingToComp.triggerRedraw();
      }
    }
    const periodOfCoveredToComp = currentForm.getComponent(
      "datePeriodCoveredTo"
    );
    let datePeriodCoveredTo =
      moment(
        currentForm?.submission?.data?.containerNonTax
          ?.containerNonTaxResident?.datePeriodCoveredTo
      ) ?? 0;
    let datePeriodMinDate = moment(
      periodOfCoveredToComp.component.datePicker.minDate ?? 0
    );
    if (
      datePeriodMinDate != 0 &&
      datePeriodCoveredTo != 0 &&
      datePeriodMinDate < datePeriodCoveredTo
    ) {
      if (datePeriod < datePeriodCoveredTo) {
        currentForm.submission.data.containerNonTax.containerNonTaxResident.datePeriodCoveredTo =
          null;

        if (periodOfCoveredToComp) {
          periodOfCoveredToComp.triggerChange();
          periodOfCoveredToComp.triggerRedraw();
        }
      }
    }
    // Handle endDate
    const endDateComp = currentForm.getComponent("endDate");
    let dateEndDate =
      moment(
        currentForm?.submission?.data?.containerQualifyingDetails
          ?.containerQualifyingPeriod?.endDate
      ) ?? 0;
    let dateEndDateMinDate = moment(
      endDateComp.component.datePicker.minDate ?? 0
    );
    if (
      dateEndDateMinDate != 0 &&
      dateEndDate != 0 &&
      dateEndDateMinDate < dateEndDate
    ) {
      if (datePeriod < dateEndDate)
        currentForm.submission.data.containerQualifyingDetails.containerQualifyingPeriod.endDate =
          null;
    }
    if (endDateComp) {
      endDateComp.triggerChange();
      endDateComp.triggerRedraw();
    }
  }
};

const CommonToggleFileUploadVessels = (currentForm, actionText) => {
  //-- Handle Checkbox Logic
  // From Datagrid -> File Upload 
  if(currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels) {
    if (actionText.toLowerCase() === "confirm") {
      currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels = true;
      Common_Reset_Ship_DataGridVessel(currentForm);
    } else {
      currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels = false;
    }
  }
  // From File Upload -> Datagrid
  else {
    if (actionText.toLowerCase() === "confirm") {
      currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels = false;
      Common_Reset_Ship_DataGridVessel(currentForm);
    } else {
      currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels = true;
    }
  }

  // Update Checkbox UI
  const checkboxMoreThanFiftyVesselsComp = currentForm.getComponent("checkboxMoreThanFiftyVessels");
  if(checkboxMoreThanFiftyVesselsComp) {
    currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels = currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels;
    checkboxMoreThanFiftyVesselsComp.triggerRedraw();
  }
  
  // Visibility of component
  Helper_ComponentUpdate(
    currentForm,
    "containerVesselsFileUpload",
    currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels
  );
  
  Helper_ComponentUpdate(
    currentForm,
    "dataGridVessel1",
    !currentForm.data.containerParticulars.containerShipVessel.checkboxMoreThanFiftyVessels
  );
}

async function TriggerEvent_DownloadVesselUploadSample(vesselUploadTemplateRef) {
  let fileName = "Input_Template_File.xlsx";
  let url = config.vesselUploadInputSampleURL; 
  url = vesselUploadTemplateRef?.current?.url || url;    
  url = HelperFormatBaseUrl("/MPAFormIO", url);
  fileName = vesselUploadTemplateRef?.current?.originalName || fileName;
  getDownload(DOMPurify.sanitize(url), fileName)
    .then(() => {
    })
    .catch((err) => {
      ConsoleLog(err);
    });
}

const Common_Reset_Ship_DataGridVessel = (currentForm) => {
  if(!currentForm)
    return;
  const dgComp = currentForm.getComponent("dataGridVessel1");
  if(dgComp) {
    // Reset ui to reflect
    dgComp.setValue([
      {
          "containerDataGrid": {
              "countries": "",
              "cbIMO": false,
              "imoNumber": null,
              "vesselName": "",
              "grossTonnage": null,
              "deliveryDate3": null,
              "typeOfVesselDescription": "",
              "vesselCost": null,
              "netTonnage": null,
              "dateOfRegistration": null,
              "statusVesselDetail": 0,
              "yearBuilt": "",
              "containerCheckbox": {
                  "editVessel": false,
                  "replaceVesselButton": false,
                  "deleteVessel": false
              }
          }
      }
    ]);
  }
  const fileUploadExcelSheetComp = currentForm.getComponent("fileUploadExcelSheet");
  if(fileUploadExcelSheetComp) {
    fileUploadExcelSheetComp.setValue([]);
  }
}

export {
  DateTransferCertShip,
  CommonVesselDatagrid_GetComponent_Update,
  CommonVesselDatagrid_GetComponent_Index,
  CommonVesselDatagrid_GetComponent_ClearFields,
  CommonVesselDatagrid_IsImoNumber_Valid,
  CommonVesselDatagrid_GetDuplicatedComponent_Index,
  TriggerEvent_TotalNonQualifyingAmountFinanced,
  CommonUpdateValidation_TotalNonQualifyingAmountFinanced,
  CommonUpdateValidation_NonQualifyingAmountFinancedCost,
  CommonUpdateValidation_TypeOfFinancingArrangementObtained,
  CommonUpdateValidation_QualifyingPayment,
  CommonPopulateQuestionEDH,
  CommonHandleAllDropdownListMetadataFix,
  CommonHandleFlagOfRegistryMetadataFix,
  CommonUpdateVesselDetailsInNewForm,
  CommonHandleAllDropdownListMetadataFix_WHTShip,
  CommonHandleAllDropdownListMetadataFix_WHTShip_entityType,
  CommonHandleAllDropdownListMetadataFixV1,
  CommonHandleWHTShipDate,
  CommonToggleFileUploadVessels,
  TriggerEvent_DownloadVesselUploadSample,
  Common_Reset_Ship_DataGridVessel
};
