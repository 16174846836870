import { Grid } from "@mui/material";
import IDPCard from "./IDPCard";
import MSICard from "./MSICard";
import WHTCard from "./WHTCard";
import ASFACard from "./ASFACard";

export default function Group1() {
  return (
          <Grid container sx={{
            width: '100%',
            display: 'flex',
            marginTop: {xs: 4},
            justifyContent: {xs: 'center', sm: 'start', md: 'start'}
          }} columnSpacing={{xs: 0, sm: 2, md: 2}}>
            <Grid item>
              <IDPCard/>
            </Grid>
            <Grid item>
              <WHTCard/>
            </Grid>
            <Grid item>
              <MSICard/>
            </Grid>
            <Grid item>
              <ASFACard />
            </Grid>
          </Grid>
  );
}
