import {ASFAS_INFO, ASFAS_BENEFIT, ASFAS_ELIGIBLE, ASFAS_APPLY} from './ASFAConstant';
import {generateHeaderWithInfo, generateSubHeaderWithInfo, generateSubHeaderToRedirect} from '../Common/genericFunction';
import { useHistory } from "react-router-dom";


export default function ASFAShip() {
  const history = useHistory();
  
  function redirectToContactUs() {
    history.push('/ContactUs');
    return;
  }

  return (
    <>
      {generateHeaderWithInfo('Approved Shipping Financing Arrangement (Ship) Award – ASFA(Ship)', ASFAS_INFO)}
      {generateSubHeaderWithInfo('Benefits', ASFAS_BENEFIT)}
      {generateSubHeaderWithInfo('Who is eligible', ASFAS_ELIGIBLE)}
      {generateSubHeaderToRedirect('How to apply', ASFAS_APPLY, redirectToContactUs)}
    </>
  )
}