import React, { useEffect, useState } from "react";
// Pagination
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import "../ApplicationListing.css";
import "../../../GlobalCss.css";

// Get Data
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApplicationListingAPIAsync,
  getApplicationListingPaginationAsync,
} from "../../../Redux/NestedApplicationListing/ApplicationListingSlice";
import { withdrawFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import { useHistory } from "react-router-dom";
// Icons
import { IconArrowLeft, IconArrowNarrowRight } from "@tabler/icons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import StatusChip from "../../ApplicationForm/StatusChip";
import ApplicationStatusOptions from "../../../Common/ApplicationStatusOptions";
import changes from "../../../Images/file-alert.svg";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import ErrorDialog from "../../../Common/ErrorDialog";
import ActionDialog from "../../../Common/ActionDialog";
import detectBrowser from "../../../Common/FindBrowser";
import SafariTextField from "../../../Common/SafariTheme";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import { config } from "../../../config";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Spinner from "../../../Common/Spinner";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { TablePaginationWithSelectBoxAndSelectIcon } from "../../../Common/CommonUtils";

let isIDP;
let isWHTC;
let isWHTS;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h5>This line not rendered.</h5>;
    }
    return this.props.children;
  }
}

export default function Applications(props) {
  const findBrowser = detectBrowser();
  const loginUser = loginHelper.getLoginUser();
  const loginUserScheme = loginUser?.Schemes;
  let schema;

  switch (loginUserScheme) {
    case "IDP":
      schema = 1;
      break;
    case "WHT":
      schema = 2;
      break;
    case "IDP, WHT":
    case "IDP,WHT":
      if (props.schema) {
        schema = parseInt(props.schema);
      }
      else {
        schema = 1;
      }
      break;
    default:
      schema = 0;
  }

  const [searchBar, setSearchBar] = useState();
  const [scheme, setScheme] = useState(schema);
  const [status, setStatus] = useState("All");
  const [caseId, setCaseId] = useState();
  const [UEN, setUEN] = useState();
  const [company, setCompany] = useState();
  const [schextension, setSchextension] = useState("0");
  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);
  const [currentFormStatus, setCurrentFormStatus] = useState(0);
  const [startSpinner, setStartSpinner] = useState(false);
  const [firstLoad, setFirstLoad] = useState(null);
  const pageAccessedByReload = window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.type)
    .includes("reload");

  const onchanges = (event) => {
    setCaseId(event.target.value);
  };

  const handleChange1 = (event) => {
    setScheme(event.target.value);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [isIconShowWHTMOFCR, setIsIconShowWHTMOFCR] = useState();

  const [typeDialog, setTypeDialog] = useState();
  const [actionName, setActionName] = useState();
  const [actionId, setActionId] = useState();

  const dispatch = useDispatch();
  const history = useHistory();
  //Get Data
  const { list } = useSelector((state) => ({ ...state.applicationListing }));
  let rowsResponse = [];
  let title;
  let pathName;

  const checkErrorStatusAndOpenErrorStatusDialog = () => {
    if (sessionStorage.getItem("error") !== "409") {
      setOpenErrorStatusDialog(true);
    }
  };

  if (list) {
    rowsResponse = list;
  }
  if (firstLoad == null) {
    isIDP = scheme != null && scheme === 1;
    isWHTS = scheme != null && scheme === 2;
    isWHTC = scheme != null && scheme === 3;
    setFirstLoad(scheme);
  } else {
    isIDP = firstLoad === 1;
    isWHTS = firstLoad === 2;
    isWHTC = firstLoad === 3;
  }
  if (isIDP) {
    title = "Sea Transport Industry Digital Plan";
    pathName = "/IDPMainForm";
  } else if (isWHTC) {
    title = "Withholding Tax Container";
    pathName = "/WHTQEntityTypeForm";
  } else if (isWHTS) {
    title = "Withholding Tax Ship";
    pathName = "/WHTQEntityTypeForm";
  }

  if (list !== undefined) {
    if (typeof list[list.length - 1] === "number") {
      window.sessionStorage.setItem(
        "TotalRow",
        JSON.stringify(list[list.length - 1])
      );
    }
  }



  const handleDialog = async (text, type) => {
    const dataforfetch = {
      skip: 0,
      limit: 10,
      scheme: scheme,
    };

    if (text === "Yes" && type === "delete") {
      setStartSpinner(true);
      dispatch(
        deleteApplicationListingAPIAsync({
          formId: actionName,
          submissionId: actionId,
          skipLimit: dataforfetch,
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          if (originalPromiseResult === false) {
            setStartSpinner(false);
            checkErrorStatusAndOpenErrorStatusDialog();
          } else {
            props.handleAction("Deleted");
            setStartSpinner(false);
            handleChangePage2(0, page + 1);
          }
        })
        .catch((err) => {
          checkErrorStatusAndOpenErrorStatusDialog();
          setStartSpinner(false);
        });
    }

    if (text === "Yes" && type === "withdraw") {
      setStartSpinner(true);
      dispatch(
        withdrawFormAPIAsync({
          currentFormStatus: currentFormStatus,
          formId: actionName,
          submissionId: actionId,
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          if (originalPromiseResult === false) {
            setStartSpinner(false);
            checkErrorStatusAndOpenErrorStatusDialog();
          } else {
            props.handleAction("Withdrawn");
            setStartSpinner(false);
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        })
        .catch(() => {
          checkErrorStatusAndOpenErrorStatusDialog();
          setStartSpinner(false);
        });
    }
  };

  function searchPage(searchObject) {
    let numS;
    switch (status) {
      case "All":
        numS = 0;
        break;
      case "Draft":
        numS = ApplicationStatusOptions.Draft;
        break;
      case "Submitted":
        numS = ApplicationStatusOptions.Submitted;
        break;
      case "Processing":
        numS = ApplicationStatusOptions.Processing;
        break;
      case "Approved":
        numS = ApplicationStatusOptions.Approved;
        break;
      case "Rejected":
        numS = ApplicationStatusOptions.Rejected;
        break;
      case "Withdrawal":
        numS = ApplicationStatusOptions.Withdrawal;
        break;
      case "Lapsed":
        numS = ApplicationStatusOptions.Lapsed;
        break;
      case "Acknowledged":
        numS = ApplicationStatusOptions.Acknowledged;
        break;
      default:
        numS = 0;
        break;
    }
    let isAssignToMe = (assigntoMe === true) ? "1" : "0";

    let skipLimit;
    if (searchObject !== undefined && searchObject !== null) {
      skipLimit = searchObject;
    } else {
      skipLimit = {
        skip: 0,
        limit: 10,
        scheme: scheme,
        applicationStatus: numS,
        search: searchBar,
        caseId: caseId,
        UEN: UEN,
        company: company,
        schemaExtension: schextension,
        submissionStartDate: filterDate1,
        submissionEndDate: filterDate2,
        isAssignedToMe: isAssignToMe,
      };
    }
    window.sessionStorage.setItem('Search1', JSON.stringify(skipLimit));
    setStartSpinner(true);
    dispatch(getApplicationListingPaginationAsync(skipLimit))
      .unwrap()
      .then((originalPromiseResult) => {
        if (!originalPromiseResult || originalPromiseResult?.length === 0) {
          sessionStorage.clear();
        }         
        setStartSpinner(false);
      })
      .catch(() => {
        setStartSpinner(false);
        checkErrorStatusAndOpenErrorStatusDialog(); 
      });
    if(searchObject?.pageInfo) {
      setPage(searchObject?.pageInfo?.page);
      setRowsPerPage(searchObject?.pageInfo?.rowsPerPage);
    }
    else {
      setPage(0);
      setRowsPerPage(10);
    }
  }

  useEffect(() => {
    let searchObject = null;
    if (window.sessionStorage.getItem('Search1') !== undefined && window.sessionStorage.getItem('Search1') !== null) {
      searchObject = JSON.parse(window.sessionStorage.getItem('Search1'));
      setCaseId(searchObject?.caseId);
      setScheme(searchObject?.scheme);
    }
    if (window.sessionStorage.getItem('Search2') !== undefined && window.sessionStorage.getItem('Search2') !== null) {
      searchObject.pageInfo = JSON.parse(window.sessionStorage.getItem('Search2'));
      setPage(searchObject?.pageInfo?.page);
      setRowsPerPage(searchObject?.pageInfo?.rowsPerPage);
      searchObject.skip = (searchObject?.pageInfo?.page) * searchObject?.pageInfo?.rowsPerPage;
      searchObject.limit = searchObject?.pageInfo?.rowsPerPage;
    }
    if (pageAccessedByReload) {
      sessionStorage.clear();
    }
    if (loginUserScheme === "") {
      history.push((loginUser?.CorppassRole === "User") ? "/Dashboard" : "/PublicUser");
    }
    searchPage(searchObject);
  }, [status]);

  function goToForm(scheme, schemaExtension, id, status, caseID) {
    window.sessionStorage.setItem("info", JSON.stringify(status));
    history.push({
      pathname: (function () {
        switch (scheme) {
          case 1: {
            if (schemaExtension === 4) return "/IDPClaimForm";
            else if (schemaExtension === 3) return "/IDPChangeRequestForm";
            else if (schemaExtension === 8) return "/IDPAppealForm";
            else return "/IDPMainForm";
          }
          case 2: {
            if (schemaExtension === 4) return "/WHTShipClaimForm";
            else if (schemaExtension === 3) return "/WHTShipChangeRequestForm";
            else if (schemaExtension === 8) return "/WHTShipAppealForm";
            else return "/WHTShipForm";
          }
          case 3: {
            if (schemaExtension === 4) return "/WHTContainerClaimForm";
            else if (schemaExtension === 3)
              return "/WHTContainerChangeRequestForm";
            else if (schemaExtension === 8) return "/WHTContainerAppealForm";
            else return "/WHTContainerForm";
          }
          case 4: {
            if (schemaExtension === 4) return "/MSIAISMainClaimForm";
            else if (schemaExtension === 3)
              return "/MSIAISMainChangeRequestForm";
            else if (schemaExtension === 8) return "/MSIAISMainAppealForm";
            else return "/MSIAISMainForm";
          }
          default:
            break;
        }
      })(),
      search: `?_id=${id}`,
      state: {
        caseID: caseID,
      },
    });
  }

  function goToChange(scheme, schemaExtension, id) {
    history.push({
      pathname: (function () {
        switch (scheme) {
          case 1: {
            if (schemaExtension === 4) return "/IDPClaimForm";
            else if (schemaExtension === 3) return "/IDPChangeRequestForm";
            else if (schemaExtension === 8) return "/IDPAppealForm";
            else return "IDPChangeRequestForm";
          }
          case 2: {
            if (schemaExtension === 4) return "/WHTShipClaimForm";
            else if (schemaExtension === 3) return "/WHTShipChangeRequestForm";
            else if (schemaExtension === 8) return "/WHTShipAppealForm";
            else return "/WHTShipChangeRequestForm";
          }
          case 3: {
            if (schemaExtension === 4) return "/WHTContainerClaimForm";
            else if (schemaExtension === 3)
              return "/WHTContainerChangeRequestForm";
            else if (schemaExtension === 8) return "/WHTContainerAppealForm";
            else return "/WHTContainerChangeRequestForm";
          }
          case 4: {
            if (schemaExtension === 4) return "/MSIAISMainClaimForm";
            else if (schemaExtension === 3)
              return "/MSIAISMainChangeRequestForm";
            else if (schemaExtension === 8) return "/MSIAISMainAppealForm";
            else return "/MSIAISMainChangeRequestForm";
          }
          default:
            break;
        }
      })(),
      search: `?mainSubmissionId=${id}&status=${status}`,
    });
  }

  function goToAppeal(scheme, schemaExtension, id) {
    history.push({
      pathname: (function () {
        switch (scheme) {
          case 1: {
            return "/IDPAppealForm";
          }
          case 2: {
            return "/WHTShipAppealForm";
          }
          case 3: {
            return "/WHTContainerAppealForm";
          }
          case 4: {
            return "/MSIAISMainAppealForm";
          }
          default:
            break;
        }
      })(),
      search: `?mainSubmissionId=${id}&status=${status}`,
    });
  }

  // === Pagination (NOT Tablepagination) ================
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage2 = (event, newPage) => {
    setStartSpinner(true);
    let numS;
    switch (status) {
      case "All":
        numS = 0;
        break;
      case "Draft":
        numS = ApplicationStatusOptions.Draft;
        break;
      case "Submitted":
        numS = ApplicationStatusOptions.Submitted;
        break;
      case "Processing":
        numS = ApplicationStatusOptions.Processing;
        break;
      case "Approved":
        numS = ApplicationStatusOptions.Approved;
        break;
      case "Rejected":
        numS = ApplicationStatusOptions.Rejected;
        break;
      case "Withdrawal":
        numS = ApplicationStatusOptions.Withdrawal;
        break;
      case "Lapsed":
        numS = ApplicationStatusOptions.Lapsed;
        break;
      case "Acknowledged":
        numS = ApplicationStatusOptions.Acknowledged;
        break;
      default:
        numS = 0;
        break;
    }

    let isAssignToMe = (assigntoMe === true) ? "1" : "0";

    const skipLimit = {
      skip: newPage * rowsPerPage - rowsPerPage,
      limit: rowsPerPage,
      scheme: scheme,
      applicationStatus: numS,
      search: searchBar,
      caseId: caseId,
      UEN: UEN,
      company: company,
      schemaExtension: schextension,
      submissionStartDate: filterDate1,
      submissionEndDate: filterDate2,
      isAssignedToMe: isAssignToMe,
    };
    dispatch(getApplicationListingPaginationAsync(skipLimit))
      .unwrap()
      .then(() => {
        setStartSpinner(false);
        setPage(newPage - 1);
        const searchPageMemory = {
          page: newPage - 1,
          rowsPerPage: rowsPerPage,
        }
        window.sessionStorage.setItem('Search2', JSON.stringify(searchPageMemory));
      })
      .catch(() => {
        setStartSpinner(false);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setStartSpinner(true);
    let numS;
    switch (status) {
      case "All":
        numS = 0;
        break;
      case "Draft":
        numS = ApplicationStatusOptions.Draft;
        break;
      case "Submitted":
        numS = ApplicationStatusOptions.Submitted;
        break;
      case "Processing":
        numS = ApplicationStatusOptions.Processing;
        break;
      case "Approved":
        numS = ApplicationStatusOptions.Approved;
        break;
      case "Rejected":
        numS = ApplicationStatusOptions.Rejected;
        break;
      case "Withdrawal":
        numS = ApplicationStatusOptions.Withdrawal;
        break;
      case "Lapsed":
        numS = ApplicationStatusOptions.Lapsed;
        break;
      case "Acknowledged":
        numS = ApplicationStatusOptions.Acknowledged;
        break;
      default:
        numS = 0;
        break;
    }

    let isAssignToMe = (assigntoMe === true) ? "1" : "0";

    const skipLimit = {
      skip: 0,
      limit: event.target.value,
      scheme: scheme,
      applicationStatus: numS,
      search: searchBar,
      caseId: caseId,
      UEN: UEN,
      company: company,
      schemaExtension: schextension,
      submissionStartDate: filterDate1,
      submissionEndDate: filterDate2,
      isAssignedToMe: isAssignToMe,
    };
    dispatch(getApplicationListingPaginationAsync(skipLimit))
      .unwrap()
      .then(() => {
        setStartSpinner(false);
        setPage(0);
      })
      .catch(() => {
        setStartSpinner(false);
      });
  };

  const displaySchemesAccordingToConfiguration = (loginUserScheme) => {
    return <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      value={scheme}
      autoWidth
      onChange={handleChange1}
    >
      {config.isIDP === "true" && loginUserScheme?.includes("IDP") &&
        <MenuItem value={1}>IDP</MenuItem>}
      {loginUserScheme?.includes("WHT") &&
        [
          <MenuItem value={2}>WHT Ship</MenuItem>,
          <MenuItem value={3}>WHT Container</MenuItem>
        ]}
    </Select>;
  }
  const [filterDate1, setFilterDate1] = useState(null);
  const [filterDate2, setFilterDate2] = useState(null);
  const [assigntoMe, setAssignToMe] = useState(false);



  function Row(props) {
    const { row } = props;
    const [withdrawText, setWithdrawText] = useState("Withdraw")
    const [acceptedDateOver30DaysStatus, setAcceptedDateOver30DaysStatus] = useState('');
    const [anchorElPopper, setAnchorElPopper] = useState(null);
    const [openPopper, setOpenPopper] = useState(false);
    const actions = {
      "Preview": 0,
      "Appeal": 0,
      "Accept": 0,
      "Claim": 0,
      "ChangeRequest": 0,
      "Delete": 0,
      "Withdraw": 0
    }

    const handleIcon = (e, params) => {
      setAnchorElPopper(e.currentTarget);
      setOpenPopper((prev) => !prev);
      setIsIconShowWHTMOFCR(params.isIconShowWHTMOFCR);
    };

    const getAcceptanceDate = async (id) => {
      let letterOfOfferAcceptanceDate;
      let requestorActionDate;
      if (id) {
        letterOfOfferAcceptanceDate = await fetch(`${config.appURL}/api/LetterOfOffer/GetBySubmissionId?SubmissionId=${id}`, 'GET', null);
        if (letterOfOfferAcceptanceDate.status === 200 || letterOfOfferAcceptanceDate.status === 201) {
          requestorActionDate = letterOfOfferAcceptanceDate.data.data?.requestorActionDate;
          const todayDate = new Date();
          const iDate = new Date(requestorActionDate);
          if ((Math.abs(todayDate - iDate) / (1000 * 3600 * 24) > 30)) {
            setAcceptedDateOver30DaysStatus("ACCEPTED_DATE_OVER_30_DAYS");
          }
        }
        else {
          setTypeDialog("letter Of Offer Acceptance Date")
          checkErrorStatusAndOpenErrorStatusDialog();
          setAcceptedDateOver30DaysStatus("");
        }
      }
    }

    const getMenuValidation = (schema, extension, submissionId) => {
      if (schema === 1) {
        setWithdrawText("Withdraw Application");
      } else if (schema === 2 || schema === 3) {
        if (parseInt(extension) === 1) {
          setWithdrawText("Withdraw Self-declaration");
        } else {
          setWithdrawText("Withdraw Submission");
        }
      }
      let jwt = loginHelper.getLoginJwt();
      let configuration;
      if (jwt) {
        configuration = {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        };
      }
      // cannot delete below query due to sonarqube because deletion will cause many jira issues
      const query = `${config.appURL}/api/Application/GetInternetListingMenuAction?schemaId=${schema}&schemaExtensionId=${extension}&submissionId=${submissionId}`;

      // cannot delete below due to sonarqube because deletion will cause many jira issues
      axios
        .get(query, configuration)
        .then((res) => {
          const dataResponse = res.data.actions;
          setActionMenu(dataResponse);
          setOpenMenu(true);
          return dataResponse;
        })
        .catch((err) => {
          checkErrorStatusAndOpenErrorStatusDialog();
        });
    };

    const [actionMenu, setActionMenu] = React.useState(actions);
    // === Actions IconDots Menu =======================
    const [anchorElNested, setAnchorElNested] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const open = Boolean(openMenu);
    let changeRequests;
    let claims;
    let appeals;
    let mainRow = JSON.parse(window.sessionStorage.getItem("row"));

    let showingDeleteMenu = 1;
    let showClaimAppealMenu = 1;
    let showReUploadLetterOfAcceptanceMenu = 1;

    if (row && row.length !== 0) {
      if (mainRow !== "") {
        let currentRow = undefined;
        if (row.id === mainRow) {
          currentRow = row;
        }
        if (currentRow === undefined) {
          currentRow = row.NestedChangeRequest.find((x) => x.id === mainRow);
        }
        if (currentRow === undefined) {
          currentRow = row.NestedClaim.find((x) => x.id === mainRow);
        }
        if (currentRow === undefined) {
          currentRow = row.NestedAppeal.find((x) => x.id === mainRow);
        }
        if (currentRow !== undefined) {
          showingDeleteMenu = currentRow.status === "Draft";
          showClaimAppealMenu = currentRow.scheme === 1;

          showReUploadLetterOfAcceptanceMenu =
            actionMenu.Accept === 0 &&
            currentRow.scheme === 1 &&
            currentRow.status === "Approved" &&
            currentRow.internalstatus === 10;
          if (showReUploadLetterOfAcceptanceMenu) getAcceptanceDate(currentRow.id);
        }
      }
    }

    const handleClickNested = (event, params) => {
      setAnchorElNested(event.currentTarget);
      window.sessionStorage.setItem("row", JSON.stringify(params.id));
    };

    const handleCloseNested = () => {
      setAnchorElNested(null);
      setOpenMenu(false);
    };

    const nestedMenu = (params) => (
      <div>
        <IconButton
          aria-label="settings"
          id="actions-button"
          aria-controls={open ? "inbox-actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            getMenuValidation(params.scheme, params.schemaExtension, params.id);
            handleClickNested(e, params);
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="applicationlisting-actions-menu"
          anchorEl={anchorElNested}
          open={open}
          onClose={handleCloseNested}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            disabled={actionMenu.Preview === 0}
            onClick={(e) => {
              e.preventDefault();
              let theform = undefined;
              if (row.id === mainRow) {
                theform = row;
              }
              if (theform === undefined) {
                theform = row.NestedChangeRequest.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedClaim.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedAppeal.find((x) => x.id === mainRow);
              }
              if (theform !== undefined) {
                goToForm(
                  theform.scheme,
                  theform.schemaExtension,
                  mainRow,
                  theform.status,
                  theform.caseId
                );
              }
            }}
          >
            Preview
          </MenuItem>

          <MenuItem
            disabled={actionMenu.ChangeRequest === 0}
            onClick={(e) => {
              e.preventDefault();

              let theform = undefined;
              if (row.id === mainRow) {
                theform = row;
              }
              if (theform === undefined) {
                theform = row.NestedChangeRequest.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedClaim.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedAppeal.find((x) => x.id === mainRow);
              }
              if (theform !== undefined) {
                goToChange(theform.scheme, theform.schemaExtension, mainRow);
              }
            }}
          >
            Change Request
          </MenuItem>

          <MenuItem
            style={{ display: showClaimAppealMenu ? "none" : "block" }}
            disabled={actionMenu.Appeal === 0}
            onClick={(e) => {
              e.preventDefault();
              let theform = undefined;
              if (row.id === mainRow) {
                theform = row;
              }
              if (theform === undefined) {
                theform = row.NestedChangeRequest.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedClaim.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedAppeal.find((x) => x.id === mainRow);
              }
              if (theform !== undefined) {
                goToAppeal(theform.scheme, theform.schemaExtension, mainRow);
              }
            }}
          >
            Application to MOF
          </MenuItem>

          <MenuItem
            disabled={actionMenu.Withdraw === 0}
            onClick={(e) => {
              e.preventDefault();
              let theform = undefined;
              if (row.id === mainRow) {
                theform = row;
              }
              if (theform === undefined) {
                theform = row.NestedChangeRequest.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedClaim.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedAppeal.find((x) => x.id === mainRow);
              }
              if (theform !== undefined) {
                goToWithdrawDraft(
                  mainRow,
                  theform.scheme,
                  theform.schemaExtension,
                  theform.internalstatus
                );
              }
            }}
          >
            {withdrawText}
          </MenuItem>

          <MenuItem
            style={{ display: actionMenu.Accept === 0 ? "none" : "block" }}
            disabled={actionMenu.Accept === 0}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/Acceptance/${mainRow}`);
            }}
          >
            Accept Offer
          </MenuItem>

          {acceptedDateOver30DaysStatus === "ACCEPTED_DATE_OVER_30_DAYS" ? (
            <MenuItem
              style={{
                display: showReUploadLetterOfAcceptanceMenu ? "show" : "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/Acceptance/${mainRow}`);
              }}
            >
              View Letter of Offer / Acceptance
            </MenuItem>
          ) : (
            <MenuItem
              style={{
                display: showReUploadLetterOfAcceptanceMenu ? "show" : "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/Acceptance/${mainRow}`);
              }}
            >
              Reupload Letter of Acceptance
            </MenuItem>
          )}

          <MenuItem
            disabled={actionMenu.Claim === 0}
            style={{ display: showClaimAppealMenu ? "block" : "none" }}
            onClick={(e) => {
              e.preventDefault();
              history.push(
                `/IDPClaimForm?mainSubmissionId=${mainRow}&status=${status}`
              );
            }}
          >
            Submit Claim
          </MenuItem>

          <MenuItem
            style={{ display: showingDeleteMenu ? "block" : "none" }}
            disabled={actionMenu.Delete === 0}
            onClick={(e) => {
              e.preventDefault();

              let theform = undefined;
              if (row.id === mainRow) {
                theform = row;
              }
              if (theform === undefined) {
                theform = row.NestedChangeRequest.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedClaim.find((x) => x.id === mainRow);
              }
              if (theform === undefined) {
                theform = row.NestedAppeal.find((x) => x.id === mainRow);
              }
              if (theform !== undefined) {
                goToDeleteDraft(
                  mainRow,
                  theform.scheme,
                  theform.schemaExtension,
                  theform.status
                );
              }
            }}
          >
            Delete Draft
          </MenuItem>
        </Menu>
      </div>
    );

    function goToDeleteDraft(id, schema, schemaExtension, status) {
      const formId = (function () {
        switch (schema) {
          case 1: {
            if (schemaExtension === 4) return config.form_name_target_idp_claim;
            else if (schemaExtension === 3) return config.form_name_target_idp_cr;
            else if (schemaExtension === 8) return "IDPAppealForm";
            else return config.form_name_target_idp_main;
          }
          case 2: {
            if (schemaExtension === 4) return "WHTShipClaimForm";
            else if (schemaExtension === 3) return config.form_name_target_ship_cr;
            else if (schemaExtension === 8) return config.form_name_target_ship_mof;
            else return config.form_name_target_ship_main;
          }
          case 3: {
            if (schemaExtension === 4) return "WHTContainerClaim";
            else if (schemaExtension === 3) return config.form_name_target_container_cr;
            else if (schemaExtension === 8) return config.form_name_target_container_mof;
            else return config.form_name_target_container_main;
          }
          case 4: {
            if (schemaExtension === 4) return "MSIAISMainClaimForm";
            else if (schemaExtension === 3)
              return "MSIAISMainChangeRequestForm";
            else if (schemaExtension === 8) return "MSIAISMainAppealForm";
            else return config.form_name_target_msi_main;
          }
          default:
            break;
        }
      })();
      setActionName(formId);
      setActionId(id);
      setOpenDialog(true);
      setTypeDialog("delete");
      handleCloseNested();
    }

    function goToWithdrawDraft(id, schema, schemaExtension, status) {
      const formId = function () {
        switch (schema) {
          case 1: {
            if (schemaExtension === 4) return config.form_name_target_idp_claim;
            else if (schemaExtension === 3) return config.form_name_target_idp_cr;
            else if (schemaExtension === 8) return "IDPAppealForm";
            else return config.form_name_target_idp_main;
          }
          case 2: {
            if (schemaExtension === 4) return "WHTShipClaimForm";
            else if (schemaExtension === 3) return config.form_name_target_ship_cr;
            else if (schemaExtension === 8) return config.form_name_target_ship_mof;
            else return config.form_name_target_ship_main;
          }
          case 3: {
            if (schemaExtension === 4) return "WHTContainerClaimForm";
            else if (schemaExtension === 3) return config.form_name_target_container_cr;
            else if (schemaExtension === 8) return config.form_name_target_container_mof;
            else return config.form_name_target_container_main;
          }
          case 4: {
            if (schemaExtension === 4) return "MSIAISMainClaimForm";
            else if (schemaExtension === 3)
              return "MSIAISMainChangeRequestForm";
            else if (schemaExtension === 8) return "MSIAISMainAppealForm";
            else return config.form_name_target_msi_main;
          }
          default:
            break;
        }
      };
      setActionName(formId);
      setActionId(id);
      setOpenDialog(true);
      setTypeDialog("withdraw");
      setCurrentFormStatus(status);
      handleCloseNested();
    }

    const resultNestedChangeRequest = row.NestedChangeRequest.length;
    const resultNestedClaim = row.NestedClaim.length;
    const resultNestedAppeal = row.NestedAppeal.length;
    const [topLevelKey, setTopLevelKey] = useState("");
    let isNested = false;
    if (resultNestedChangeRequest || resultNestedClaim || resultNestedAppeal) {
      isNested = true;
    }
    let nestedRows = JSON.parse(
      window.sessionStorage.getItem("NestedRows") || "[]"
    );

    const nestedRedirect = (params) => (
      <div>
        <span className="univers-55roman-normal-mine-shaft-16px">
          <Link
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              goToForm(
                params.scheme,
                params.schemaExtension,
                params.id,
                params.status,
                params.caseId
              )
            }
          >
            {params.caseId}
          </Link>
          <div className="theiconplace" style={{
            display:
              params.isIconShowWHTMOFCR === 1 ||
                params.isIconShowWHTMOFCR === 2 ||
                params.isIconShowWHTMOFCR === 3
                ? "show"
                : "none",
          }}>
            <img src={changes} id="iconsize" alt="changesIcon"
              onClick={(e) => handleIcon(e, params)} />
          </div>
          <Popper
            open={openPopper}
            anchorEl={anchorElPopper}
            placement="right-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={50}>
                <Paper>
                  {(() => {
                    if (isIconShowWHTMOFCR)
                      switch (isIconShowWHTMOFCR) {
                        case 1:
                          return (
                            <Typography
                              sx={{
                                p: 1,
                                color: "white",
                                backgroundColor: "black",
                                fontSize: "10px",
                              }}
                            >
                              Change Request Required
                            </Typography>
                          );
                        case 2:
                          return (
                            <Typography
                              sx={{
                                p: 1,
                                color: "white",
                                backgroundColor: "black",
                                fontSize: "10px",
                              }}
                            >
                              Application to MOF Required
                            </Typography>
                          );
                        case 3:
                          return (
                            <Typography
                              sx={{
                                p: 1,
                                color: "white",
                                backgroundColor: "black",
                                fontSize: "10px",
                              }}
                            >
                              - Change Request Required <br />- Application to MOF Required
                            </Typography>
                          );

                        default:
                          return null;
                      }
                  })()}
                </Paper>
              </Fade>
            )}
          </Popper>
        </span>
        <br />
        <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
          Created on{" "}
          {new Date(params.submissionStartDate).toLocaleDateString("en-SG", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
      </div>
    );

    function getEntityTypeName(shortform) {
      let r;
      switch (shortform) {
        case "r1":
          r = "SRS";
          return r;
        case "r2":
          r = "MSI-AIS & SRS";
          return r;
        case "r3":
          r = "MSI-ML (Ship)";
          return r;
        case "r4":
          r = "MSI-ML (Container)";
          return r;
        case "r5":
          r = "ASFA (Ship)";
          return r;
        case "r6":
          r = "ASFA (Container)";
          return r;
        default:
          return null;
      }
    }

    if (resultNestedAppeal) {
      appeals = row.NestedAppeal.map((nested) => {
        return (
          <Collapse
            key={nested.caseId}
            in={Boolean(row.NestedAppeal.length)}
            unmountOnExit
          >
            <Table
              aria-label="collapsible table"
              sx={{
                borderBottom: "0px none !important",
                border: "none !important",
              }}
            >
              <TableBody sx={{ border: "none !important" }}>
                <TableRow
                  key={nested.caseId}
                  sx={{
                    borderBottom: "0px none !important",
                    border: "none !important",
                  }}
                >
                  <TableCell align="left" sx={{ width: "70px" }}>
                    <></>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "270px" }}>
                    {nestedRedirect(nested)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "172px" }}>
                    {isIDP === true
                      ? nested.solution
                      : getEntityTypeName(nested.entityType)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "160px" }}>
                    {isIDP === true ? nested.vendor : nested.lender}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    <StatusChip type={nested.status} />
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    <div>
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.username !== "PLACEHOLDER_CORPASS_USER" &&
                          nested.username}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          0,
                          nested.LastUpdated.indexOf(",")
                        )}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          nested.LastUpdated.indexOf(",") + 1,
                          nested.LastUpdated.length
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "111px" }}>
                    {nestedMenu(nested)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        );
      });
    }

    if (resultNestedChangeRequest) {
      changeRequests = row.NestedChangeRequest.map((nested) => {
        return (
          <Collapse
            key={nested.caseId}
            in={Boolean(row.NestedChangeRequest.length)}
            unmountOnExit
          >
            <Table
              aria-label="collapsible table"
              sx={{
                borderBottom: "0px none !important",
                border: "none !important",
              }}
            >
              <TableBody sx={{ border: "none !important" }}>
                <TableRow
                  key={nested.caseId}
                  sx={{
                    borderBottom: "0px none !important",
                    border: "none !important",
                  }}
                >
                  <TableCell align="left" sx={{ width: "70px" }}>
                    <></>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "270px" }}>
                    {nestedRedirect(nested)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "172px" }}>
                    {isIDP === true
                      ? nested.solution
                      : getEntityTypeName(nested.entityType)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "160px" }}>
                    {isIDP === true ? nested.vendor : nested.lender}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    <StatusChip type={nested.status} />
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    <div>
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.username !== "PLACEHOLDER_CORPASS_USER" &&
                          nested.username}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          0,
                          nested.LastUpdated.indexOf(",")
                        )}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          nested.LastUpdated.indexOf(",") + 1,
                          nested.LastUpdated.length
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "111px" }}>
                    {nestedMenu(nested)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        );
      });
    }

    if (resultNestedClaim) {
      claims = row.NestedClaim.map((nested) => {
        return (
          <Collapse
            key={nested.caseId}
            in={Boolean(row.NestedClaim.length)}
            unmountOnExit
          >
            <Table
              aria-label="collapsible table"
              sx={{
                borderBottom: "0px none !important",
                border: "none !important",
              }}
            >
              <TableBody sx={{ border: "none !important" }}>
                <TableRow
                  key={nested.caseId}
                  sx={{
                    borderBottom: "0px none !important",
                    border: "none !important",
                  }}
                >
                  <TableCell align="left" sx={{ width: "70px" }}>
                    <></>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "270px" }}>
                    {nestedRedirect(nested)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "172px" }}>
                    {isIDP === true
                      ? nested.solution
                      : getEntityTypeName(nested.entityType)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "160px" }}>
                    {isIDP === true ? nested.vendor : nested.lender}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    <StatusChip type={nested.status} />
                  </TableCell>

                  <TableCell align="left" sx={{ width: "158px" }}>
                    <div>
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.username !== "PLACEHOLDER_CORPASS_USER" &&
                          nested.username}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          0,
                          nested.LastUpdated.indexOf(",")
                        )}
                      </span>
                      <br />
                      <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                        {nested.LastUpdated.substring(
                          nested.LastUpdated.indexOf(",") + 1,
                          nested.LastUpdated.length
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "111px" }}>
                    {nestedMenu(nested)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        );
      });
    }

    return (
      <React.Fragment>
        <TableRow
          key={topLevelKey}
          sx={{ borderBottom: "0px none !important" }}
        >
          <TableCell align="left" sx={{ width: "70px" }}>
            {isNested ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  if (nestedRows.includes(row.caseId)) {
                    const index = nestedRows.indexOf(row.caseId);
                    if (index !== -1) {
                      nestedRows.splice(index, 1);
                    }
                    setTopLevelKey("0");
                  } else {
                    nestedRows.push(row.caseId);
                    setTopLevelKey(row.caseId);
                  }
                  window.sessionStorage.setItem(
                    "NestedRows",
                    JSON.stringify(nestedRows)
                  );
                }}
              >
                {nestedRows.includes(row.caseId) ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell align="left" sx={{ width: "270px" }}>
            {nestedRedirect(row)}
          </TableCell>
          <TableCell align="left" sx={{ width: "172px" }}>
            {isIDP === true ? row.solution : getEntityTypeName(row.entityType)}
          </TableCell>
          <TableCell align="left" sx={{ width: "160px" }}>
            {isIDP === true ? row.vendor : row.lender}
          </TableCell>
          <TableCell align="left" sx={{ width: "158px" }}>
            <StatusChip type={row.status} />
          </TableCell>
          <TableCell align="left" sx={{ width: "158px" }}>
            <div>
              <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                {row.username !== "PLACEHOLDER_CORPASS_USER" && row.username}
              </span>
              <br />
              <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                {row.LastUpdated.substring(0, row.LastUpdated.indexOf(","))}
              </span>
              <br />
              <span className="msi-ais univers-55roman-normal-hit-gray-12px wraptext">
                {row.LastUpdated.substring(
                  row.LastUpdated.indexOf(",") + 1,
                  row.LastUpdated.length
                )}
              </span>
            </div>
          </TableCell>
          <TableCell align="left" sx={{ width: "111px" }}>
            {nestedMenu(row)}
          </TableCell>
        </TableRow>

        {/*
                    Claim
                */}
        {resultNestedClaim ? (
          <TableRow sx={{ borderWidth: "0 !important" }}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              colSpan={7}
            >
              <Collapse
                in={nestedRows.includes(row.caseId)}
                unmountOnExit
                sx={{ marginTop: "8px", marginBottom: "8px" }}
              >
                <div className="nested-listing">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    mt={"6px"}
                  >
                    Claim
                  </Typography>
                </div>
                {claims}
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}

        {/*
                    Change Request
                */}
        {resultNestedChangeRequest ? (
          <TableRow sx={{ borderWidth: "0 !important" }}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              colSpan={7}
            >
              <Collapse
                in={nestedRows.includes(row.caseId)}
                unmountOnExit
                sx={{ marginTop: "8px", marginBottom: "8px" }}
              >
                <div className="nested-listing">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    mt={"4px"}
                  >
                    Change Request
                  </Typography>
                </div>
                {changeRequests}
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}

        {/*
                    Appeal to MOF
                */}
        {resultNestedAppeal ? (
          <TableRow sx={{ borderWidth: "0 !important" }}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              colSpan={7}
            >
              <Collapse
                in={nestedRows.includes(row.caseId)}
                unmountOnExit
                sx={{ marginTop: "8px", marginBottom: "8px" }}
              >
                <div className="nested-listing">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    mt={"4px"}
                  >
                    Appeal to MOF
                  </Typography>
                </div>
                {appeals}
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      status: PropTypes.string.isRequired,
      caseId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  function NestedDisplay() {
    return (
      <>
        {rowsResponse
          .slice(0, rowsPerPage)
          .map(
            (row) =>
              row.id !== undefined && (
                <ErrorBoundary key={row.id}>
                  {typeof row == "object" ? <Row row={row} /> : <></>}
                </ErrorBoundary>
              )
          )}
      </>
    );
  }

  return (
    <>
      <ErrorDialog
        open={openErrorStatusDialog}
        setOpen={setOpenErrorStatusDialog}
        type={typeDialog}
      />
      <Grid container sx={{ pt: 3, pb: 5 }} alignItems="center">
        <Button
          onClick={() => history.push("/Dashboard")}
          className="nav-back-btn"
        >
          <IconArrowLeft color="#666" />
        </Button>
        <Breadcrumbs
          sx={{ display: "inline-block", verticalAlign: "middle" }}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            sx={{ cursor: "pointer" }}
            m={1}
            className="univers-65-bold-scarpa-flow-12px"
            onClick={() => history.push("/Dashboard")}
          >
            Dashboard
          </Link>
          <Link
            underline="none"
            m={1}
            className="univers-65-bold-scarpa-flow-12px"
          >
            <span className="surname-1">{title} Award</span>
          </Link>
        </Breadcrumbs>
        <br />
        <br />
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <h1 className="h1-global" style={{ fontFamily: "Univers-65Bold" }}>
              {title}
              <br />
              Application Listing
            </h1>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"end"}
            xs={3}
          >
            <Button
              className="large button-large univers-65-bold-white-16px"
              endIcon={<IconArrowNarrowRight />}
              onClick={() =>
                history.push({
                  pathname: pathName,
                  state: { id: 1 },
                })
              }
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          padding: 4,
          border: "1px #EAECF0 solid",
          borderRadius: "12px 12px 0 0",
          backgroundColor: "white",
          marginLeft: "0 !important",
          width: "100% !important",
        }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {findBrowser !== "Safari" ? (
          <Grid item xs={4}>
            <Typography variant="h5">Case ID</Typography>
            <div>
              <TextField
                sx={{ "& .MuiInputBase-root": { minWidth: 100 } }}
                placeholder="Enter Number"
                value={caseId}
                onChange={onchanges}
              ></TextField>
            </div>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Typography variant="h5">Case ID</Typography>
            <div>
              <SafariTextField
                sx={{ "& .MuiInputBase-root": { minWidth: 100 } }}
                placeholder="Enter Number"
                value={caseId}
                onChange={onchanges}
                variant="filled"
              ></SafariTextField>
            </div>
          </Grid>
        )}
        <Grid item xs={4} sx={{ paddingLeft: 4 }}>
          <Typography variant="h5">Scheme</Typography>
          <FormControl
            sx={{
              width: 230,
              "& .MuiInputBase-root": {
                minWidth: 150,
              },
            }}
          >
            {displaySchemesAccordingToConfiguration(loginUserScheme)}
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ width: 200, mt: 3.1 }}>
          <Button
            onClick={() => {
              sessionStorage.removeItem("Search2");
              setFirstLoad(null);
              if (pageAccessedByReload) {
                sessionStorage.clear();
              }
              searchPage();
            }}
            className="button-large univers-65-bold-white-16px"
          >
            Search
          </Button>
        </Grid>
      </Grid>

      <div
        style={{
          display: "inherit",
          backgroundColor: "white",
          marginBottom: 48,
          border: "1px solid #EAECF0",
          borderRadius: "12px",
        }}
      >
        {startSpinner ? (
          <Spinner />
        ) : isIDP === true ? (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ width: "70px" }} />
                  <TableCell align="left" sx={{ width: "270px" }}>
                    CASE ID
                  </TableCell>
                  <TableCell align="left" sx={{ width: "172px" }}>
                    SOLUTION NAME
                  </TableCell>
                  <TableCell align="left" sx={{ width: "160px" }}>
                    VENDOR
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    STATUS
                  </TableCell>
                  <TableCell align="left" sx={{ width: "158px" }}>
                    LAST UPDATED
                  </TableCell>
                  <TableCell align="left" sx={{ width: "111px" }}>
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>{NestedDisplay()}</TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>CASE ID</TableCell>
                  <TableCell>ENTITY TYPE</TableCell>
                  <TableCell>1st LENDER</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>LAST UPDATED</TableCell>
                  <TableCell>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{NestedDisplay()}</TableBody>
            </Table>
          </TableContainer>
        )}

        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            height: 80,
          }}
        >
          {JSON.parse(window.sessionStorage.getItem("TotalRow")) !== null && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={JSON.parse(window.sessionStorage.getItem("TotalRow"))}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Entries"}
              sx={TablePaginationWithSelectBoxAndSelectIcon()}
            />
          )}
          {JSON.parse(window.sessionStorage.getItem("TotalRow")) !== null && (
            <Pagination
              onChange={handleChangePage2}
              page={page + 1}
              count={Math.ceil(
                JSON.parse(window.sessionStorage.getItem("TotalRow")) /
                rowsPerPage
              )}
              sx={{
                "& .MuiPaginationItem-circular.Mui-selected": {
                  backgroundColor: "#0065BD",
                  color: "white",
                },
              }}
            />
          )}
        </Grid>
      </div>
      <div>
        <ActionDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          actionName={actionName}
          response={handleDialog}
          type={typeDialog}
        ></ActionDialog>
      </div>
    </>
  );
}
